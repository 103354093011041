// FacilitiesPage.jsx
import React from 'react';
import './FacilitiesPage.css';

const FacilitiesPage = () => {
    const productionData = [
        { injector: "50 Ton", qty: 2, make: "Ferromatic", weight: "1-80 gm" },
        { injector: "55 Ton Vertical", qty: 1, make: "TKC", weight: "1-90 gm" },
        { injector: "60 Ton (Vertical Rot.)", qty: 2, make: "Manthman", weight: "1-90 gm" },
        { injector: "80 Ton", qty: 1, make: "Ferromatic", weight: "1-110 gm" },
        { injector: "90 Ton", qty: 1, make: "Shinewell", weight: "1-120 gm" },
        { injector: "110 Ton", qty: 1, make: "Ferromatic", weight: "5-340 gm" },
        { injector: "150 Ton", qty: 1, make: "Shinewell", weight: "5-220 gm" },
        { injector: "200 Ton", qty: 1, make: "Bole", weight: "1-345 gm" }
    ];

    const toolRoomData = [
        { category: "VMC Milling Machine", machines: [
            { name: "HAAS VF2", qty: 1, make: "HAAS" },
            { name: "HAAS VF5-4 AXIS", qty: 1, make: "HAAS" },
            { name: "DMG MORI SEIKI", qty: 1, make: "HAAS" }
        ]},
        { category: "Conventional Machine", machines: [
            { name: "MILLING MACHINE", qty: 1, make: "ESTEAM" },
            { name: "MILLING MACHINE-DRO", qty: 3, make: "ESTEAM" },
            { name: "SURFACE GRINDING", qty: 1, make: "OMEGA" },
            { name: "SURFACE GRINDING-Hydraulics", qty: 2, make: "PERFECT" },
            { name: "DRILLING MACHINE_RADIAL", qty: 1, make: "EFCO" },
            { name: "DRILLING MACHINE_PILLAR", qty: 2, make: "EFCO" }
        ]},
        { category: "Electric Discharge Machine", machines: [
            { name: "EDM", qty: 1, make: "ELECTRONIC" }
        ]}
    ];

    return (
        <div className="facilities-page">
            <div className="facilities-container">
                <h1 className="main-title">Our Facilities</h1>

                {/* Production Section */}
                <section className="facility-section">
                    <h2 className="section-title">Production Facilities</h2>
                    <div className="content-wrapper">
                        
                        <div className="table-container">
                        <h4 className="section-title" style={{color:'black'}}>Moulding Machines</h4>
                            <table className="facility-table">
                                <thead>
                                    <tr>
                                        <th>Injector</th>
                                        <th>Qty</th>
                                        <th>Make</th>
                                        <th>Part Weight</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productionData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.injector}</td>
                                            <td>{item.qty}</td>
                                            <td>{item.make}</td>
                                            <td>{item.weight}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="images-container1">
                            <img src="./assets/images_colored/f1.jpg" alt="Production Facility 1" className="facility-image" />
                            <img src="./assets/images_colored/f2.jpg" alt="Production Facility 2" className="facility-image" />
                        </div>
                    </div>
                </section>

                {/* Tool Room Section */}
                <section className="facility-section">
                    <h2 className="section-title">Tool Room Facilities</h2>
                    <div className="content-wrapper">
                        <div className="table-container">
                            {toolRoomData.map((category, index) => (
                                <div key={index} className="category-section">
                                    <h3 className="category-title">{category.category}</h3>
                                    <table className="facility-table">
                                        <thead>
                                            <tr>
                                                <th>Machine</th>
                                                <th>Qty</th>
                                                <th>Make</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {category.machines.map((machine, idx) => (
                                                <tr key={idx}>
                                                    <td>{machine.name}</td>
                                                    <td>{machine.qty}</td>
                                                    <td>{machine.make}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>
                        {/* <div className="images-grid">
                            <img src="/api/placeholder/300/300" alt="Tool Room 1" className="facility-image" />
                            <img src="/api/placeholder/300/300" alt="Tool Room 2" className="facility-image" />
                            <img src="/api/placeholder/300/300" alt="Tool Room 3" className="facility-image" />
                            <img src="/api/placeholder/300/300" alt="Tool Room 4" className="facility-image" />
                        </div> */}
                        <div className="images-container">
                            <img src="./assets/images_colored/f3.jpg" alt="Production Facility 3" className="facility-image" />
                            <img src="./assets/images_colored/f4.jpg" alt="Production Facility 4" className="facility-image" />
                            <img src="./assets/images_colored/f5.jpg" alt="Production Facility 5" className="facility-image" />
                            <img src="./assets/images_colored/f6.jpg" alt="Production Facility 6" className="facility-image" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default FacilitiesPage;