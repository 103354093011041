import React from 'react'
import './Footer.css';
import { FaLocationDot } from "react-icons/fa6";
import { GoLocation } from "react-icons/go";

function Footer() {
  return (
    <div>
        {/* <!-- Footer-start --> */}
    <footer style={{padding:"20px"}}>
        <div class="container">
            <div class="footer-wrapper">
                <div class="about-col">
                <div style={{ }}>
                        <a href="/" class="logo">
                            <img class="footer-logo" src="./assets/images_colored/sri.png" alt="Rodio logo" />

                        </a>
                        <div style={{
                            //   backgroundColor: '#ff7800', // Brown background
                            color: '#ff7800',
                            padding: '0px 20px',
                            fontFamily: 'serif',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            letterSpacing: '1px',
                            //   boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            textTransform: 'uppercase',
                            border: 'none',
                          marginLeft:'-20px',
                          marginRight:'53px',
                        }}>
                            Sri Engineering
                        </div></div>
                    <p style={{marginTop:'10px'}}>
                    We specialize in innovative engineering solutions that drive progress.
                    </p>
                    {/* <div class="social-links-block">
                        <ul class="social-links">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                        />
                      </svg>
                    </span>
                  </a>
                            </li>
                            <li>
                                <a href="https://x.com/?lang=en" target="_blank">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                        ></path>
                      </svg>
                    </span>
                  </a>
                            </li>
                            <li>
                                <a href="https://pk.linkedin.com" target="_blank">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                        />
                      </svg>
                    </span>
                  </a>
                            </li>
                        </ul>
                    </div> */}
                </div>
                <div class="links-col">
                    <h4 class="footer-title">Menu</h4>
                    <ul class="footer-list-items" >
                        <li class="footer-list-item">
                            <a href="/">
                  <span class="footer-list-text">Home</span>
                </a>
                        </li>
                        <li class="footer-list-item">
                            <a href="/about">
                  <span class="footer-list-text">About Us</span>
                </a>
                        </li>
                        <li class="footer-list-item">
                            <a href="/service">
                  <span class="footer-list-text">Product & Services</span>
                </a>
                        </li>
                        <li class="footer-list-item">
                            <a href="/facility">
                  <span class="footer-list-text">Facilities</span>
                </a>
                        </li>
                        
                        <li class="footer-list-item" style={{marginRight:"80px"}}>
                            <a href="/contact">
                  <span class="footer-list-text">Contact</span>
                </a>
                        </li>
                    </ul>
                </div>
                <div class="contact-col">
                    <h4 class="footer-title">Contact</h4>
                    
                    <ul className="footer-list-items" style={{ listStyle: "none", padding: 0 }}>
  <li style={{ display: "flex", alignItems: "center", gap: "8px" }}>
    <GoLocation style={{ color: "rgb(255, 120, 0)", fontSize: "18px" ,width:'50px',marginBottom:'50px'}} />
    <p style={{ margin: 0, fontSize: "16px", lineHeight: "1.5" }}>
      # B-63, 5th Cross, Dyavasandra Industrial Estate, Mahadevapura Post, Bangalore-560048.
    </p>
  </li>


                        <li class="footer-list-item">
                            <a href="tel:080022223344">
                  <span class="footer-list-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M493.09 351.3L384.7 304.8a31.36 31.36 0 0 0-36.5 8.9l-44.1 53.9A350 350 0 0 1 144.5 208l53.9-44.1a31.35 31.35 0 0 0 8.9-36.49l-46.5-108.5A31.33 31.33 0 0 0 125 .81L24.2 24.11A31.05 31.05 0 0 0 0 54.51C0 307.8 205.3 512 457.49 512A31.23 31.23 0 0 0 488 487.7L511.19 387a31.21 31.21 0 0 0-18.1-35.7zM456.89 480C222.4 479.7 32.3 289.7 32.1 55.21l99.6-23 46 107.39-72.8 59.5C153.3 302.3 209.4 358.6 313 407.2l59.5-72.8 107.39 46z"
                      ></path>
                    </svg>
                  </span>
                  <span class="footer-list-text">+919845202377 <br/> +919845109360</span>
                </a>
                        </li>
                        <li class="footer-list-item">
                            <a href="mailto:info@rodio.com">
                  <span class="footer-list-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5l0 13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1l0-13.6c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2 464 456c0 4.4-3.6 8-8 8L56 464c-4.4 0-8-3.6-8-8l0-179.8zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5L0 456c0 30.9 25.1 56 56 56l400 0c30.9 0 56-25.1 56-56l0-255.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z"
                      />
                    </svg>
                  </span>
                  <span class="footer-list-text">info@rodio.com</span>
                </a>
                        </li>
                    </ul>
                </div>
                <div class="subscribe-col">
                  
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6489.450555336846!2d77.69540997412074!3d12.997674214308033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110022cd3035%3A0x28a4a058c744b585!2sSri%20Engineering!5e1!3m2!1sen!2sin!4v1740206463858!5m2!1sen!2sin" width="300" height="300" ></iframe>
                </div>
            </div>
            <div class="copy-right">
  <p class="copyright-text">
    Copyright 2025, 
    <a href="https://excerptech.com/" target="_blank">
      Designed By Excerpt Technologies
    </a>
  </p>
</div>

        </div>
    </footer>
    {/* <!-- Footer-End --> */}
    </div>
  )
}

export default Footer
