import React from 'react';
import './Procurement.css';

const Procurement = () => {
  const partners = [
    {
      group: 'Group 1',
      companies: [
        {
          name: 'DuPont',
          logo: './assets/images_colored/r1.jpg',
        },
        {
          name: 'Brakes India Private Limited',
          logo: './assets/images_colored/r2.jpg',
        },
        {
          name: 'Gujarat Petrosynthese Limited',
          logo: './assets/images_colored/r3.jpg',
        }
      ]
    },
    {
      group: 'Group 2',
      companies: [
        {
          name: 'Polyplastics',
          logo: './assets/images_colored/r4.jpg',
        },
        {
          name: 'Solvay',
          logo: './assets/images_colored/r5.jpg',
        },
        {
          name: 'Tipco Group',
          logo: './assets/images_colored/r6.jpg',
        },
        {
          name: 'Autotech Sirmax',
          logo: './assets/images_colored/r7.jpg',
        }
      ]
    }
  ];

  return (
    <div className="procurement-container">
      <div className="procurement-header">
        <h2> RAW MATERIAL PROCUREMENT </h2>
      </div>
      
      <div className="procurement-content">
        {partners.map((group, groupIndex) => (
          <div key={groupIndex} className="partner-group">
            <div className="company-grid">
              {group.companies.map((company, index) => (
                <div key={index} className="company-card">
                  <div className="logo-container">
                    <img
                      src={company.logo}
                      alt={`${company.name} logo`}
                      className="company-logo"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Procurement;


// import React, { useState, useRef } from 'react';

// const ImageGallery = () => {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const dialogRef = useRef(null);
  
//   const containerStyle = {
//     maxWidth: '1200px',
//     margin: '0 auto',
//     padding: '0 20px'
//   };

//   const gridStyle = {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
//     gap: '20px',
//     margin: '20px 0'
//   };

//   const imageContainerStyle = {
//     position: 'relative',
//     width: '100%'
//   };

//   const buttonStyle = {
//     width: '100%',
//     padding: '0',
//     border: 'none',
//     background: 'none',
//     cursor: 'pointer',
//     borderRadius: '8px',
//     overflow: 'hidden',
//     transition: 'transform 0.3s ease',
//   };

//   const imageStyle = {
//     width: '100%',
//     height: '250px',
//     objectFit: 'cover',
//     display: 'block'
//   };

//   const dialogStyle = {
//     border: 'none',
//     background: 'transparent',
//     width: '100%',
//     height: '100%',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     padding: '20px',
//     maxWidth: '100vw',
//     maxHeight: '100vh'
//   };

//   const dialogOverlayStyle = {
//     position: 'relative',
//     maxWidth: '1200px',
//     margin: '0 auto',
//     zIndex: 1000
//   };

//   const closeButtonStyle = {
//     position: 'absolute',
   
//     right: '0',
//     background: 'transparent',
//     border: 'none',
//     color: 'white',
//     cursor: 'pointer',
//     padding: '10px',
//     fontSize: '16px'
//   };

//   const lightboxImageStyle = {
//     width: '100%',
//     maxHeight: '80vh',
//     objectFit: 'contain',
//     borderRadius: '8px',
//     background: ''
//   };

//   // Add styles for dialog backdrop
//   const dialogPolyfillStyle = document.createElement('style');
//   dialogPolyfillStyle.textContent = `
//     dialog::backdrop {
//       background: rgba(0, 0, 0, 0.75);
//     }
//   `;
//   document.head.appendChild(dialogPolyfillStyle);
  
//   const images = [
//     { 
//       id: 1, 
//       url: './assets/images_colored/m21.jpg',
//       alt: 'Machine 3' 
//     },
//     { 
//       id: 2, 
//       url: './assets/images_colored/m21.jpg',
//       alt: 'Machine 21' 
//     },
//     { 
//       id: 3, 
//       url: './assets/images_colored/m1.jpg',
//       alt: 'Machine 1' 
//     },
//     { 
//       id: 4, 
//       url: './assets/images_colored/r5.jpg',
//       alt: 'Robot 5' 
//     }
//   ];

//   const handleImageClick = (image) => {
//     setSelectedImage(image);
//     dialogRef.current?.showModal();
//   };

//   const handleClose = () => {
//     dialogRef.current?.close();
//     setSelectedImage(null);
//   };

//   // Hover effect
//   const handleMouseEnter = (e) => {
//     e.currentTarget.style.transform = 'scale(1.05)';
//   };

//   const handleMouseLeave = (e) => {
//     e.currentTarget.style.transform = 'scale(1)';
//   };

//   return (
//     <div style={containerStyle}>
//       <div style={gridStyle}>
//         {images.map((image) => (
//           <div key={image.id} style={imageContainerStyle}>
//             <button
//               onClick={() => handleImageClick(image)}
//               style={buttonStyle}
//               onMouseEnter={handleMouseEnter}
//               onMouseLeave={handleMouseLeave}
//             >
//               <img
//                 src={image.url}
//                 alt={image.alt}
//                 style={imageStyle}
//               />
//             </button>
//           </div>
//         ))}
//       </div>

//       <dialog
//         ref={dialogRef}
//         style={dialogStyle}
//         onClick={handleClose}
//       >
//         <div 
//           style={dialogOverlayStyle}
//           onClick={e => e.stopPropagation()}
//         >
//           <button
//             onClick={handleClose}
//             style={closeButtonStyle}
//           >
//             Close
//           </button>
//           {selectedImage && (
//             <img
//               src={selectedImage.url}
//               alt={selectedImage.alt}
//               style={lightboxImageStyle}
//             />
//           )}
//         </div>
//       </dialog>
//     </div>
//   );
// };

// export default ImageGallery;



