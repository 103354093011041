import React from 'react';
import ProcessFlow from './ProcessFlow';

import Procurement from  './Procurement'
import './Procurement.css';
import Products from './Products';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Navigation } from 'swiper/modules';
import ImageGallery from '../Home/ImageGallery';

function Service() {
  return (
    <div>

<section class="page-header-section page-header-colored" style={{marginTop:"10vh"}}>
        <div class="container">
          <div class="page-header-wrapper">
            <div class="page-header-content">
              <div class="page-title">
                <h1>Services</h1>
              </div>
              <div class="breadcrumbs">
                <ul>
                  <li>
                    <a href="/"> Home </a>
                  </li>
  
                  <li>services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      {/* <ProcessFlow/> */}
       {/* <!-- Work-v1-Start --> */}
    
       <Products/>
       <ImageGallery/>
    <Procurement/>
   

    

    
    


            {/* <!-- Clients-Section-Start --> */}
            <section className="clients-section client-swiper-section">
                <div className="container wow animate__animated custom-fadeInUp" data-wow-duration="2s">
                    <div className="slider-header">
          <h2 className="primary--heading">Our Products.</h2>
          <div className="client-slider-btns">
            <div className="client-button-prev slider-btn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path
                  d="M203.9 405.3c5.877 6.594 5.361 16.69-1.188 22.62c-6.562 5.906-16.69 5.375-22.59-1.188L36.1 266.7c-5.469-6.125-5.469-15.31 0-21.44l144-159.1c5.906-6.562 16.03-7.094 22.59-1.188c6.918 6.271 6.783 16.39 1.188 22.62L69.53 256L203.9 405.3z"
                ></path>
              </svg>
            </div>
            <div className="client-button-next slider-btn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                <path
                  d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                ></path>
              </svg>
            </div>
          </div>
        </div>

                    <Swiper
                        spaceBetween={50}
                        slidesPerView={4}
                        navigation={{
                            prevEl: '.client-button-prev',
                            nextEl: '.client-button-next',
                        }}
                        modules={[Navigation]}
                        className="swiper clients-swiper"
                    >
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/pp1.jpg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/pp5.jpg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/pp6.jpg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/pp2.jpg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/pp3.jpg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/pp4.jpg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                       
                        {/* <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/c7.jpeg"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a className="client-block">
                                <img
                                    src="./assets/images_colored/c8.png"
                                    alt="Client Logo"
                                    className="swiper-img"
                                />
                            </a>
                        </SwiperSlide> */}


                    </Swiper>
                </div>
            </section>
            {/* <!-- Clients-Section-End --> */}
   

    </div>
  )
}

export default Service
