import React from 'react'
import Iso from '../About/Iso'
import FeatureGrid from './FeatureGrid'

function Quality() {
  return (
    <div>
      <section class="page-header-section page-header-colored" style={{marginTop:"10vh"}}>
        <div class="container">
          <div class="page-header-wrapper">
            <div class="page-header-content">
              <div class="page-title">
                <h1>Quality</h1>
              </div>
              <div class="breadcrumbs">
                <ul>
                  <li>
                    <a href="/"> Home </a>
                  </li>
  
                  <li>Quality</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
       <FeatureGrid/>
      <Iso/>
    </div>
  )
}

export default Quality





// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Pagination, Autoplay } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";

// const HeroSlider = () => {
//   return (
//     <section className="hero-section-1">
//       <div className="hs1">
//         <div className="slider hs1-content">
//           <div className="hs1-content-wrapper">
//             <Swiper 
//               modules={[Navigation,Autoplay]}
//               navigation
//               pagination={{ clickable: true }}
//               autoplay={{ delay: 3000 }}
//               loop
              
//             >
//               <SwiperSlide>
//                 <div className="content">
//                   <div className="hs1-bg">
//                     <img src="./assets/images_colored/homepage.jpg" alt="hero-slider-1" />
//                   </div>
//                   <div className="container">
//                     <div className="hero-main-content">
//                       <div className="headings">
//                         <h5 className="sub-heading" style={{ fontWeight: "bold", fontSize: "25px" }}>
//                           Welcome to Sri Engineering.
//                         </h5>
//                         <h1 className="hero--heading">Shaping industries with quality solutions.</h1>
//                         <div className="hero-btns">
//                           <a className="button" href="/about">
//                             <div className="button-inner">
//                               <span>LEARN MORE</span>
//                             </div>
//                           </a>
//                           <a className="button white--btn" href="/contact">
//                             <div className="button-inner">
//                               <span>Contact Us</span>
//                             </div>
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </SwiperSlide>

//               <SwiperSlide>
//                 <div className="content">
//                   <div className="hs1-bg">
//                     <img src="./assets/images_colored/hero-slider-1-img-2.jpg" alt="hero-slider-2" />
//                   </div>
//                   <div className="container">
//                     <div className="hero-main-content">
//                       <div className="headings">
//                         <h5 className="sub-heading">Welcome to Sri Engineering.</h5>
//                         <h1 className="hero--heading">Shaping industries with quality solutions.</h1>
//                         <div className="hero-btns">
//                           <a className="button" href="/about">
//                             <div className="button-inner">
//                               <span>LEARN MORE</span>
//                             </div>
//                           </a>
//                           <a className="button white--btn" href="/contact">
//                             <div className="button-inner">
//                               <span>Contact Us</span>
//                             </div>
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </SwiperSlide>

//               <SwiperSlide>
//                 <div className="content">
//                   <div className="hs1-bg">
//                     <img src="./assets/images_colored/hero-slider-1-img-3.jpg" alt="hero-slider-3" />
//                   </div>
//                   <div className="container">
//                     <div className="hero-main-content">
//                       <div className="headings">
//                         <h5 className="sub-heading">Welcome to Rodio.</h5>
//                         <h1 className="hero--heading">Your ideas realized for real world.</h1>
//                         <div className="hero-btns">
//                           <a className="button" href="/about">
//                             <div className="button-inner">
//                               <span>LEARN MORE</span>
//                             </div>
//                           </a>
//                           <a className="button white--btn" href="/contact">
//                             <div className="button-inner">
//                               <span>Contact Us</span>
//                             </div>
//                           </a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </SwiperSlide>
//             </Swiper>
//           </div>
//         </div>
//       </div>
      
//     </section>
//   );
// };

// export default HeroSlider;
