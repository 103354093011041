import React from 'react';
import './FacilitiesQA.css';

const FacilitiesQAPage = () => {
  const qaEquipmentData = [
    { name: "Non contact VMM", qty: 1, make: "Customised Technologies" },
    { name: "MFI", qty: 1, make: "International Equipments" },
    { name: "Height Gauge", qty: 1, make: "Mitutoyo" },
    { name: "Inside Caliper", qty: 1, make: "China" },
    { name: "Vernier Caliper", qty: 1, make: "Mitutoyo" },
    { name: "Micrometer", qty: 1, make: "Mitutoyo" },
    { name: "Plug Gauges", qty: "As Required", make: "-" },
    { name: "Pin Gauges", qty: 131, make: "AMS" },
    { name: "Slip Gauges", qty: 83, make: "Peacock" }
  ];

  const rawMaterialData = [
    { material: "PP GF30 FR", stock: 250, min: 100, max: 500, location: "A-1", status: "high" },
    { material: "PC/ABS FR", stock: 300, min: 150, max: 600, location: "A-2", status: "medium" },
    { material: "PA66 GF30", stock: 400, min: 200, max: 800, location: "A-3", status: "high" },
    { material: "PBT GF30", stock: 350, min: 175, max: 700, location: "A-4", status: "low" }
  ];

  return (
    <div className="qa-facilities-page">
      {/* QA Facilities Section */}
      <section className="section-container">
        <div className="section-header">
          <h2 className="section-title">MEASURING RESOURCES</h2>
        </div>
        
        <div className="section-content">
          <div className="table-container">
            <table className="qa-table">
              <thead>
                <tr>
                  <th>MME</th>
                  <th>Qty</th>
                  <th>Make</th>
                </tr>
              </thead>
              <tbody>
                {qaEquipmentData.map((item, index) => (
                  <tr key={index}>
                    <td className="font-medium">{item.name}</td>
                    <td>{item.qty}</td>
                    <td>{item.make}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
          <div className="image-grid-container">
            <div className="image-grid">
              <img src="./assets/images_colored/qa3.jpg" alt="QA Equipment 1" className="qa-image" />
              <img src="./assets/images_colored/qa2.jpg" alt="QA Equipment 2" className="qa-image" />
              <img src="./assets/images_colored/qa1.jpg" alt="QA Equipment 3" className="qa-image" />
              <img src="./assets/images_colored/qa5.jpg" alt="QA Equipment 4" className="qa-image" />
             
            </div>
          </div>
        </div>
      </section>

      {/* Raw Material Store Section */}
      <section className="section-container">
        <div className="section-header">
          <h2 className="section-title"> FACILITIES - RAW MATERIAL STORE</h2>
        </div>
        
        <div className="section-content">
          <div className="table-container">
            <div className="stock-table">
              <div className="stock-table-header">
                <h3 className="stock-table-title">RAW MATERIAL STOCK STATUS / FIFO</h3>
              </div>
              <table className="qa-table">
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Stock</th>
                    <th>Min</th>
                    <th>Max</th>
                    <th>Location</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {rawMaterialData.map((item, index) => (
                    <tr key={index}>
                      <td className="font-medium">{item.material}</td>
                      <td className="text-right">{item.stock}</td>
                      <td className="text-right">{item.min}</td>
                      <td className="text-right">{item.max}</td>
                      <td>{item.location}</td>
                      <td>
                        <span className={`status-indicator status-${item.status}`}></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          
          <div className="image-grid-container">
            <div className="image-grid1">
              <img src="./assets/images_colored/aq11.jpg" alt="Raw Material Store Status Board" className="qa-image" />
              <img src="./assets/images_colored/aq11.jpg" alt="Raw Material Store Shelves" className="qa-image" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FacilitiesQAPage;


