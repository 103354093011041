import React from 'react';

const FeatureGrid = () => {
  const containerStyle = {
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '40px',
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    fontFamily: 'Arial, sans-serif',
  };

  const qualityPolicyContainer = {
    textAlign: 'center',
    padding: '10px',
    marginBottom: '40px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  };

  const policyHeader1 = {
    fontSize: '28px',
    fontWeight: 'bold',
    color: 'red',
    marginTop:"60px",
    marginBottom: '25px',
  };

  const policyHeader= {
    fontSize: '28px',
    fontWeight: 'bold',
    color: 'red',
    marginTop:"20px",
    marginBottom: '25px',
  };

  const policyText = {
    fontSize: '18px',
    color: '#333',
    lineHeight: '1.6',
    maxWidth: '1000px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'justify',
  };

  const headerStyle = {
    textAlign: 'center',
    marginBottom: '50px',
  };

  const descriptionStyle = {
    color: '#4B5563',
    fontSize: '1.3rem',
    lineHeight: '1.7',
    maxWidth: '1200px',
    margin: '0 auto 30px',
    padding: '0 20px',
  };

  const titleStyle = {
    fontSize: '1.8rem',
    fontWeight: '600',
    color: '#1E3A8A',
    marginBottom: '50px',
    padding: '0 20px',
    lineHeight: '1.4',
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '40px',
    padding: '20px',
  };

  const featureStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    transition: 'transform 0.3s',
  };

  const iconContainerStyle = {
    backgroundColor: '#ff7800',
    width: '120px',
    height: '120px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    boxShadow: '0 6px 15px rgba(243, 173, 43, 0.97)',
    transition: 'transform 0.3s, box-shadow 0.3s',
  };

  const featureTitleStyle = {
    fontSize: '1.55rem',
    fontWeight: '500',
    lineHeight: '1.5',
    color: 'black',
    maxWidth: '280px',
    margin: '0 auto',
  };

  const handleMouseEnter = (e) => {
    const iconContainer = e.currentTarget.querySelector('div');
    e.currentTarget.style.transform = 'translateY(-8px)';
    iconContainer.style.transform = 'scale(1.05)';
    iconContainer.style.boxShadow = '0 8px 20px rgba(241, 171, 39, 0.93)';
  };

  const handleMouseLeave = (e) => {
    const iconContainer = e.currentTarget.querySelector('div');
    e.currentTarget.style.transform = 'translateY(0)';
    iconContainer.style.transform = 'scale(1)';
    iconContainer.style.boxShadow = '0 6px 15px rgba(241, 171, 39, 0.93)';
  };

  const features = [
    { icon: "🎯", title: "Customer Focus" },
    { icon: "🏆", title: "Leadership" },
    { icon: "🤝", title: "Engagement of People" },
    { icon: "⚙️", title: "Process Approach" },
    { icon: "📈", title: "Improvement" },
    { icon: "🔗", title: "Relationship Management" },
    { icon: "📊", title: "Evidence-Based Decision Making" },
    { icon: "✅", title: "Commitment to Satisfy Applicable Requirements" },
  ];

  return (
    <div style={containerStyle}>
      
      {/* Quality Policy Section */}
      <div style={qualityPolicyContainer}>
        <p style={{ color: '#555', fontSize: '19px',textAlign:'left',fontFamily: 'Arial, sans-serif', }}>
        Continuous improvement in all our process and our technical capabilities has put us in position to serve with high quality globally acceptable products.<br/>
          Sri Engineering follows four different stages of Quality Control in plastic injection molding: 
          Design Stage, Mold & Pre-Production Inspection, In-Process Quality Control, and Final Inspection. 
          Certified with ISO 9001:2015, we ensure the highest standards of quality.
          
        </p>
        <h2 style={policyHeader1}>Quality Policy</h2>
        <p style={{textAlign:'left',fontSize: '18px',fontFamily: 'Arial, sans-serif' }}>
          We, at M/s Sri Engineering, are committed to the manufacture of Plastic Injection Moulds, Components, Jigs, and Fixtures. 
          We ensure timely delivery of services to enhance customer satisfaction through efficient resource utilization, 
          striving for continuous improvement in processes and products.
        </p>
       
      </div>
       

      {/* Existing Feature Section */}
      {/* <div style={headerStyle}>
       
        <h2 style={titleStyle}>
          Special focus areas that have made Sri Engineering an industry leader:
        </h2>
      </div> */}

      <div style={gridStyle}>
        {features.map((feature, index) => (
          <div 
            key={index} 
            style={featureStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={iconContainerStyle}>
              <span style={{ fontSize: '42px', color: 'white' }}>{feature.icon}</span>
            </div>
            <p style={featureTitleStyle}>{feature.title}</p>
          </div>
        ))}
      </div>
      <div style={headerStyle}>
       
       <h2 style={titleStyle}>
         {/* Special focus areas that have made Sri Engineering an industry leader: */}
       </h2>
     </div>
      {/* Process Details */}
      <div style={qualityPolicyContainer}>
        <h2 style={policyHeader}>Process Approach</h2>
        <p style={policyText}>✔ Control Plan & quality standards available for every part.</p>
        <p style={policyText}>✔ PPAP submitted for every new part.</p>
        <p style={policyText}>✔ 8D approach to problem-solving.</p>
        <p style={policyText}>✔ Adequate storage - Raw material – FIFO system, Stock Board system with Min & Max.</p>
        <p style={policyText}>✔ Adequate Rack storage – Customer property - Mold-Tool History card.</p>
        <p style={policyText}>✔ 5’S system implementation.</p>
      </div>
        {/* ISO Certifications */}
        <div style={qualityPolicyContainer}>
        <h2 style={policyHeader}>Certifications & Achievements</h2>
        <p style={policyText}>✔ ISO 9001:2015 Quality Management System certified by URS</p>
        <p style={policyText}>✔ Certified for SQ Mark Audit by Hyundai Motor</p>
        <p style={policyText}>✔ MACE certified Company by Maruti</p>
      </div>
    </div>
  );
};

export default FeatureGrid;
