import React,{ useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import About from "./Pages/About/About";
import Service from "./Pages/Service/Service";
import Contact from "./Pages/Contact/Contact";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import ProcessFlow from "./Pages/Service/ProcessFlow";
import Facilities from "./Pages/Facility/Facilities";
import WhatsAppButton from "./Components/WhatsAppButton/WhatsAppButton";
import Quality from "./Pages/Quality/Quality";



import Home from "./Pages/Home/Home"
import Auto from "./Pages/Service/Auto";
import HealthCare from "./Pages/Service/HealthCare";
import Electrical from "./Pages/Service/Electrical";
import Solorwind from "./Pages/Service/Solorwind";
function App() {
  const [isScrolled, setIsScrolled] = useState(false);

   // Detect scroll position
   useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) { // Adjust scroll threshold
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/process" element={<ProcessFlow />} />
        <Route path="/facility" element={<Facilities/>} />
        <Route path="/quality" element={<Quality/>} />
        <Route path="/autocomponents" element={<Auto/>} />
        <Route path="/electricalcomponents" element={<Electrical/>} />
        <Route path="/healthcare" element={<HealthCare/>} />
        <Route path="/solarandwind" element={<Solorwind/>} />
       
      </Routes>
<WhatsAppButton/>
      <Footer />
      <div
      className={`back-to-top ${isScrolled ? 'scrolled' : ''}`}
      onClick={scrollToTop}
    >
      <div className="back-to-top-inner">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M363.9 330.7c-6.271 6.918-16.39 6.783-22.62 1.188L192 197.5l-149.3 134.4c-6.594 5.877-16.69 5.361-22.62-1.188C14.2 324.1 14.73 314 21.29 308.1l159.1-144c6.125-5.469 15.31-5.469 21.44 0l159.1 144C369.3 314 369.8 324.1 363.9 330.7z"></path>
        </svg>
      </div>
    </div>
    </Router>
  );
}

export default App;
