import React from 'react';

const Core= () => {
  const coreStrengths = [
    "IN HOUSE TOOL ROOM FACILITY",
    "CAPABLE TO DEVLOP COMPONENTS FROM CONCEPT TO REALISATION",
    "WELL EQUIPPED WITH MODERN MACHINES",
    "WE HAVE HIGHLY SKILLED AND EXPERINCED EMPLOYEES",
    "FULLFIL CUSTOMER SCHEDULES IN TIME"
  ];

  const futurePlans = [
    "Increase the business with existing customer by 2024-25",
    "Add New Customers, New products & New Machines."
  ];

  const styles = {
    container: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      marginBottom: '3rem',
      padding: '0 2rem',
      backgroundColor: '#f8f9fa',
      borderRadius: '20px',
      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
      marginTop:'30px'

    },
    section: {
      backgroundColor: 'white',
      borderRadius: '15px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.08)',
      overflow: 'hidden',
      marginBottom: '2.5rem',
      border: '1px solid #eaeaea'
    },
    headerGradient: {
      background: 'linear-gradient(135deg, #FF6B6B, #FFE66D)',
      padding: '1.5rem',
      position: 'relative',
      overflow: 'hidden'
    },
    headerText: {
      color: 'white',
      fontSize: '2rem',
      fontWeight: 'bold',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
      position: 'relative',
      zIndex: '1'
    },
    contentPadding: {
      padding: '2rem',
    },
    strengthCard: {
      background: 'linear-gradient(135deg, #FFF3E0, #FFE0B2)',
      borderRadius: '12px',
      padding: '1.5rem',
      marginBottom: '1.2rem',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
      transition: 'all 0.3s ease',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      position: 'relative',
      overflow: 'hidden'
    },
    strengthText: {
      color: '#2C3E50',
      fontWeight: '600',
      fontSize: '1.2rem',
      lineHeight: '1.6',
      letterSpacing: '0.5px'
    },
    companyLogo: {
      textAlign: 'center',
      padding: '2.5rem 0',
      background: 'linear-gradient(135deg, #E3F2FD, #BBDEFB)',
      borderRadius: '15px',
      marginBottom: '2.5rem',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)'
    },
    companyName: {
      fontSize: '3rem',
      fontWeight: 'bold',
      color: '#1565C0',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
      letterSpacing: '2px'
    },
    futurePlanCard: {
      background: 'linear-gradient(135deg, #FFF3E0, #FFE0B2)',
      borderRadius: '12px',
      padding: '2rem',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
      border: '1px solid rgba(255, 255, 255, 0.5)'
    },
    planText: {
      color: '#2C3E50',
      fontWeight: '600',
      fontSize: '1.2rem',
      marginBottom: '1rem',
      lineHeight: '1.6',
      position: 'relative',
      paddingLeft: '1.5rem'
    },
    bullet: {
      position: 'absolute',
      left: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '8px',
      height: '8px',
      backgroundColor: '#FF6B6B',
      borderRadius: '50%'
    }
  };

  return (
    <div style={styles.container}>
      {/* Core Strength Section */}
      <section style={styles.section}>
        <div style={styles.headerGradient}>
          <h2 style={styles.headerText}>CORE STRENGTH</h2>
        </div>
        <div style={styles.contentPadding}>
          {coreStrengths.map((strength, index) => (
            <div 
              key={index} 
              style={styles.strengthCard}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'translateX(10px) scale(1.02)';
                e.currentTarget.style.boxShadow = '0 6px 20px rgba(0, 0, 0, 0.1)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'translateX(0) scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 15px rgba(0, 0, 0, 0.05)';
              }}
            >
              <p style={styles.strengthText}>{strength}</p>
            </div>
          ))}
        </div>
      </section>

     

      {/* Future Plans Section */}
      <section style={styles.section}>
        <div style={styles.headerGradient}>
          <h2 style={styles.headerText}>FUTURE PLANS</h2>
        </div>
        <div style={styles.contentPadding}>
          <div style={styles.futurePlanCard}>
            {futurePlans.map((plan, index) => (
              <div key={index} style={{ position: 'relative' }}>
                <div style={styles.bullet}></div>
                <p style={styles.planText}>{plan}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Core;


