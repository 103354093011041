import React from 'react';
import ImageGallery from './ImageGallery';

const Company = () => {
  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '40px auto',
      padding: '30px',
      background: 'linear-gradient(145deg, #f6f8fd, #e8eeff)',
      borderRadius: '25px',
      boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
    },
    section: {
    //   marginBottom: '40px',
      marginTop:'80px',
      padding: '25px',
      background: 'white',
      borderRadius: '20px',
      boxShadow: '0 10px 30px rgba(0,0,0,0.05)',
      transition: 'transform 0.3s ease',
      border: '1px solid rgba(0,0,0,0.05)',
    },
    sectionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // Centers the text horizontally
        marginBottom: '20px',
        padding: '15px 25px',
        background: 'linear-gradient(135deg, #ff7800, #facc15)',
        borderRadius: '15px',
        color: 'white',
        fontSize: '30px',
        fontWeight: 'bold',
        boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
        textAlign: 'center', // Ensures text inside is centered
      },
      
    customerList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '20px',
      padding: '20px',
    },
    customerItem: {
      padding: '15px 20px',
      background: 'linear-gradient(135deg, #f6f8fd, #e8eeff)',
      borderRadius: '12px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#444',
      boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
      border: '1px solid rgba(65, 88, 208, 0.1)',
      transition: 'all 0.3s ease',
    },
    productsContainer: {
      display: 'flex',
    
      justifyContent: 'center',
      flexWrap:'wrap'
    },
    productBox: {
      padding: '20px 40px',
      background: 'linear-gradient(135deg, #f6f8fd, #e8eeff)',
      borderRadius: '15px',
      fontSize: '20px',
      fontWeight: '600',
      color: '#444',
      boxShadow: '0 8px 20px rgba(0,0,0,0.08)',
      border: '1px solid rgba(65, 88, 208, 0.1)',
      textAlign: 'center',
      margin: '10px',
    },
    factoryGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '25px',
      padding: '20px',
    },
    factoryItem: {
      padding: '25px',
      background: 'linear-gradient(135deg, #f6f8fd, #e8eeff)',
      borderRadius: '15px',
      textAlign: 'center',
      boxShadow: '0 8px 20px rgba(0,0,0,0.08)',
      border: '1px solid rgba(65, 88, 208, 0.1)',
    },
    factoryValue: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#4158D0',
      marginBottom: '10px',
    },
    factoryLabel: {
      fontSize: '16px',
      color: '#666',
      fontWeight: '500',
    }
  };

  const customers = [
    'Stanzen Engineering (P) Ltd',
    'Colgate Palmolive',
    'Kwangjin India AutosytemsLtd',
    'HAL',
    'Electrohms',
    'Grindwell Norton',
    'IFB Automotive',
    'IFB Industries',
    'A O Smith'
  ];

  return (
    <div style={styles.container}>
      {/* Customer Section */}
      <div style={styles.section}
           onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
           onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
        <div style={styles.sectionHeader}>Our Customers</div>
        <div style={styles.customerList}>
          {customers.map((customer, index) => (
            <div
              key={index}
              style={styles.customerItem}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'scale(1.03)';
                e.currentTarget.style.boxShadow = '0 8px 25px rgba(0,0,0,0.1)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0 4px 15px rgba(0,0,0,0.05)';
              }}
            >
              {customer}
            </div>
          ))}
        </div>
      </div>

      {/* Main Products Section */}
      <div style={styles.section}
           onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
           onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
        <div style={styles.sectionHeader}>Main Products</div>
        <div style={styles.productsContainer}>
        <a href="/autocomponents" style={{ ...styles.productBox, textDecoration: 'none' }}>Automotive Parts</a>
        <a href="/electricalcomponents" style={{ ...styles.productBox, textDecoration: 'none' }}>Electrical Parts</a>
        <a href="/healthcare" style={{ ...styles.productBox, textDecoration: 'none' }}>Health Care Parts</a>
          {/* <div style={styles.productBox}>Electrical Parts</div>
          <div style={styles.productBox}>Health Care Parts</div> */}
        </div>
      </div>
      <ImageGallery/>

      {/* Factory Scale Section */}
      <div style={styles.section}
           onMouseEnter={e => e.currentTarget.style.transform = 'translateY(-5px)'}
           onMouseLeave={e => e.currentTarget.style.transform = 'translateY(0)'}>
        <div style={styles.sectionHeader}>Factory Scale</div>
        <div style={styles.factoryGrid}>
          <div style={styles.factoryItem}>
            <div style={styles.factoryValue}>5300 sq ft</div>
            <div style={styles.factoryLabel}>Land Area</div>
          </div>
          <div style={styles.factoryItem}>
            <div style={styles.factoryValue}>5000 sq ft</div>
            <div style={styles.factoryLabel}>Built Area</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;