import React from 'react'
import { useState } from 'react';
import Marquee from "react-fast-marquee";

function Navbar() {
    const [isShown, setIsShown] = useState(false);
    return (
        <div>

            {/* <!-- Announcement Bar HTML --> */}
            <div class="announcement-bar">
                <div class="announcement-content">
                    <ul class="announcement-bar-links">
                        <li>
                            <a href="tel:080022223344" target="_blank" class="social-link">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                                <span style={{ color: "white" }}>+919845202377</span>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:info@rodio.com" target="_blank" class="social-link">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5l0 13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1l0-13.6c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2 464 456c0 4.4-3.6 8-8 8L56 464c-4.4 0-8-3.6-8-8l0-179.8zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5L0 456c0 30.9 25.1 56 56 56l400 0c30.9 0 56-25.1 56-56l0-255.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z" /></svg>
                                <span style={{ color: "white" }}>info@rodio.com</span>
                            </a>
                        </li>

                    </ul>
                    {/* <ul class="announcement-bar-links">
                        <li>
                            <a href="https://www.twitter.com" target="_blank" class="social-link">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                    />
                                </svg>
                                <span>Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com" target="_blank" class="social-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <title>facebook</title>
                                <path
                                    d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
                                /></svg>
                                <span>Facebook</span>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.youtube.com" target="_blank" class="social-link">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" /></svg>
                                <span>Youtube</span>
                            </a>
                        </li>
                    </ul> */}
                    <Marquee style={{ fontSize: '15px', color: 'white', marginLeft: '50px', lineHeight: "18px" }}>
                        Your Trusted Partner in Injection Moulding & Engineering Moulding--Expanding Technology to Shape the Future of Engineering.
                    </Marquee>
                </div>
            </div>


            {/* <!-- Header-start --> */}
            <header class="header home-3-header sticky-nav ">

                <div class="header-wrapper">
                    <div style={{ display: "flex" }}>
                        <a href="/" class="logo">
                            <img class="hide-sticky" src="./assets/images_colored/sri.png" alt="Rodio logo" />

                        </a>
                        <div style={{
                            //   backgroundColor: '#ff7800', // Brown background
                            color: '#ff7800',
                            padding: '25px 20px',
                            fontFamily: 'serif',
                            fontSize: '24px',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            letterSpacing: '1px',
                            //   boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            textTransform: 'uppercase',
                            border: 'none'
                        }}>
                            Sri Engineering
                        </div></div>
                    <div class="header-inner-wrapper">
                        <div class="navigation-menu-wrapper menu-b538310 desktop-wrapper">
                            <ul id="menu-main-menu" class="navigation-menu desktop">
                                <li class="menu-item">
                                    <a href="/"> Home </a>
                                </li>
                                <li class="menu-item">
                                    <a href="/about"> About </a>
                                </li>
                                
                                {/* <li class="menu-item">
                                    <a href="/service">Products & Services
                                    </a>
                                </li> */}
                                <li class="menu-item menu-item-has-children">
                                    <a href="/service">Products & Services
                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z"></path></svg></div>
                                    </a>
                                    <ul class="sub-menu">
                                        <li class="menu-item">
                                            <a href="/autocomponents">Auto Components
                                            </a>
                                        </li>
                                        <li class="menu-item">
                                            <a href="/electricalcomponents">Electrical Components
                                            </a>
                                        </li>
                                        <li class="menu-item">
                                            <a href="/healthcare">Health Care
                                            </a>
                                        </li>
                                        <li class="menu-item">
                                            <a href="/solarandwind">Solar & Wind
                                            </a>
                                        </li>
                                      
                                    </ul>
                                </li>
                                <li class="menu-item">
                                    <a href="/quality">Quality </a>
                                </li>
                                <li class="menu-item">
                                    <a href="/facility"> Facilities </a>
                                </li>
                                
                              

                                <li class="menu-item">
                                    <a href="/contact"> Contact </a>
                                </li>

                                {/* <li class="menu-item">
                                    <a > Grayscale Demo </a>
                                </li> */}

                            </ul>
                        </div>

                        <div class={`hamburger direction-left ${isShown ? "shown" : ""}`}>
                            <div class="hamburger-wrapper">
                                <div class="hamburger-icon" onClick={() => setIsShown(!isShown)}>
                                    <div class="bars">
                                        <div class="bar" ></div>
                                        <div class="bar" ></div>
                                        <div class="bar"></div>
                                    </div>
                                </div>
                                <div class="hamburger-content">
                                    <div class="hamburger-content-header">
                                        <div style={{
                                            backgroundColor: '#ff7800', // Brown background
                                            color: 'white',
                                            padding: '10px 20px',
                                            fontFamily: 'serif',
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            letterSpacing: '1px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                            textTransform: 'uppercase'
                                        }}>
                                            Sri Engineering
                                        </div>
                                        <div class="hamburger-close" onClick={() => setIsShown(!isShown)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <title>window-close</title>
                                                <path
                                                    d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="navigation-menu-wrapper">
                                        <ul class="navigation-menu mobile">
                                            <li class="menu-item">
                                                <a href="/"> Home</a>
                                            </li>
                                            <li class="menu-item">
                                                <a href="/about">About</a>
                                            </li>
                                            
                                            {/* <li class="menu-item">
                                                <a href="/service"> Product & Services </a>
                                            </li> */}
                                            <li class="menu-item menu-item-has-children">
                                                <a href="/service">
                                                Product & Services
                                                    <div class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path
                                                                d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                                <ul class="sub-menu">
                                                    <li class="back-button">
                                                        <a href="">
                                                            <div class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                    <path
                                                                        d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            Back
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="/autocomponents">Auto Components
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="/electricalcomponents">Electrical Components
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="/healthcare"> Health Care
                                                        </a>
                                                    </li>
                                                   
                                                </ul>
                                            </li>
                                            <li class="menu-item">
                                                <a href="/quality"> Quality </a>
                                            </li>
                                            <li class="menu-item">
                                                <a href="/facility"> Facilities </a>
                                            </li>
                                           

                                            {/* <li class="menu-item menu-item-has-children">
                                                <a href="../index_colored.html">
                                                    Home
                                                    <div class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path
                                                                d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                                <ul class="sub-menu">
                                                    <li class="back-button">
                                                        <a href="">
                                                            <div class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                    <path
                                                                        d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            Back
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="../index_colored.html"> Home 1 </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="home-2.html"> Home 2 </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="home-3.html"> Home 3 </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="home-4.html"> Home 4 </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="menu-item menu-item-has-children">
                                                <a href="about.html">
                                                    Pages
                                                    <div class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path
                                                                d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                                <ul class="sub-menu">
                                                    <li class="back-button">
                                                        <a href="">
                                                            <div class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                    <path
                                                                        d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            Back
                                                        </a>
                                                    </li>
                                                    <li class="menu-item menu-item-has-children">
                                                        <a href="gallery-2.html">
                                                            Gallery
                                                            <div class="icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 256 512"
                                                                >
                                                                    <path
                                                                        d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </a>
                                                        <ul class="sub-menu">
                                                            <li class="back-button">
                                                                <a href="">
                                                                    <div class="icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                            <path
                                                                                d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                            ></path>
                                                                        </svg>
                                                                    </div>
                                                                    Back
                                                                </a>
                                                            </li>
                                                            <li class="menu-item">
                                                                <a href="gallery-2.html">Gallery 2-Columns </a>
                                                            </li>
                                                            <li class="menu-item">
                                                                <a href="gallery-3.html">Gallery 3-Columns </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="about.html"> About </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="services.html"> Services </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="404.html"> 404 </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="testimonial.html"> Testimonials </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="our-team.html"> Our Team </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="faqs.html"> FAQs </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="menu-item menu-item-has-children">
                                                <a href="projects.html">
                                                    Projects
                                                    <div class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path
                                                                d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                                <ul class="sub-menu">
                                                    <li class="back-button">
                                                        <a href="">
                                                            <div class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                    <path
                                                                        d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            Back
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="projects.html">Projects </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="project-detail.html"> Projects Detail </a>
                                                    </li>

                                                </ul>
                                            </li>
                                            <li class="menu-item menu-item-has-children">
                                                <a href="blog-masonry.html">
                                                    Blog
                                                    <div class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                            <path
                                                                d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                </a>
                                                <ul class="sub-menu">
                                                    <li class="back-button">
                                                        <a href="">
                                                            <div class="icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                                                                    <path
                                                                        d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                            Back
                                                        </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="blog-masonry.html">Blog Masonry</a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="blog-sidebar.html">Blog Sidebar </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="blog-detail.html"> Blog Detail </a>
                                                    </li>
                                                    <li class="menu-item">
                                                        <a href="archives.html"> Archives </a>
                                                    </li>
                                                </ul>
                                            </li> */}

                                            <li class="menu-item">
                                                <a href="/contact"> Contact </a>
                                            </li>


                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="hamburger direction-right">
                        <div class="hamburger-wrapper">
                            <div class="hamburger-icon">
                                <div class="bars">
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                    <div class="bar"></div>
                                </div>
                            </div>
                            <div class="hamburger-content">
                                <div class="hamburger-content-header">

                                    <div class="hamburger-close">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <title>window-close</title>
                                            <path
                                                d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <div class="hamburger-content-inner">
                                    <div class="hamburger-image">
                                        <div class="icon-box">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="2324.238px" height="2560px" viewBox="0 0 2324.238 2560"><path d="M2241.326,2084.205l-424.13-419.36c277.722-147.582,467.85-442.82,467.85-782.328C2285.046,395.818,1894.936,0,1415.512,0 H555.051C248.939,0,0,252.695,0,563.264v1439.132c0,310.729,248.939,563.502,555.051,563.502h1107.133h384.168 C2293.961,2565.898,2417.689,2261.271,2241.326,2084.205z M569.598,169.324h845.914c387.45,0,702.635,319.955,702.635,713.192 c0,294.85-177.224,548.325-429.214,656.88L597.597,482.944C498.193,383.228,381.74,169.324,569.598,169.324z M555.051,2396.417 c-213.979,0-388.152-176.755-388.152-394.021v-665.875l1082.028,1059.896H555.051z M2046.352,2396.417h-557.165L166.899,1101.112 V563.264c0-125.996,59.36-239.71,151.178-311.82v0.155c-7.195,129.516,75.081,263.411,161.504,351.161l1644.354,1601.886 C2194.166,2275.113,2144.895,2396.417,2046.352,2396.417z"></path></svg>
                                        </div>
                                        <img src="./assets/images_colored/hamburger-img.jpg" alt="Hamburger image" />
                                    </div>
                                    <h2 class="heading-title">We make the difference!
                                    </h2>
                                    <p>Likeness thing won’t together fruitful saw Night called you’ll image evening meat green open man. Multiply hath life own days third yielding grass lights male shed earth morning one.</p>
                                    <h3 class="follow">follow</h3>
                                </div>
                                <div class="social-links-block">
                                    <ul class="social-links">
                                        <li>
                                            <a href="http://www.facebook.com/">
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 320 512"
                                                    >
                                                        <path
                                                            d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                                                        />
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="http://www.x.com/">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path></svg>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="http://www.linkedin.com/">
                                                <span>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path
                                                            d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                                                        />
                                                    </svg>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="hamburger-overlay"></div>
                        </div>
                    </div> */}
                </div>

            </header>
            {/* <!-- Header-end --> */}
        </div>
    )
}

export default Navbar
