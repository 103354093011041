import React, { useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import { TfiEmail } from "react-icons/tfi";
function Contact() {
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    email: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/send-email", { // Ensure port 8081 is correct
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const result = await response.json();
        alert(result.message); // Notify user of success
      } else {
        const error = await response.json();
        alert(error.message); // Notify user of failure
      }
    } catch (err) {
      console.error("Error sending email: ", err);
      alert("Error sending email. Please try again later.");
    }
  };
  
  return (

    <div>
      {/* <!-- Page-header-Start --> */}
      <section className="page-header-section page-header-colored" style={{ marginTop: "10vh" }}>
        <div className="container">
          <div className="page-header-wrapper" >
            <div className="page-header-content">
              <div className="page-title">
                <h1>Contact</h1>
              </div>
              <div className="breadcrumbs">
                <ul>
                  <li>
                  <a  href='/'> Home </a>
                  </li>

                  <li>Contact</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Page-header-End -->
    <!-- Map-section-Start --> */}
      <div className="contact-page-section">
        
        <div className="container">
  <h2 className="primary--heading" style={{marginLeft:'410px',fontSize:'45px'}}>Contact Us</h2>

          <div className="map-block">
            {/* <iframe loading="lazy" src="https://maps.google.com/maps?q=London%20Eye%2C%20London%2C%20United%20Kingdom&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="London Eye, London, United Kingdom" aria-label="London Eye, London, United Kingdom"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6489.450555336846!2d77.69540997412074!3d12.997674214308033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae110022cd3035%3A0x28a4a058c744b585!2sSri%20Engineering!5e1!3m2!1sen!2sin!4v1740206463858!5m2!1sen!2sin" width="600" height="450" ></iframe>
            <div className="phone-email-block wow animate__animated custom-fadeInUp" data-wow-duration="2s">
              <a href="tel:212555999">
                <div className="icon-box-alt">
               
                <div className="content" >
                  <FiPhoneCall  style={{color:'white',fontSize:'25px'}}/>
                    {/* <div className="sub"> Phone</div> */}
                    <div className="main">+919845202377</div>
                  </div><br />
             
                  <div className="content">
                  <TfiEmail style={{color:'white',fontSize:'25px'}}/>
                    {/* <div className="sub">Email</div> */}
                    <div className="main">info@rodio.com</div>
                  </div>
                </div>

              </a>
              {/* <a href="mailto:info@rodio.com">
                        <div className="icon-box-alt">
                            <div className="icon-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M352 248v-16c0-4.42-3.58-8-8-8H168c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h176c4.42 0 8-3.58 8-8zm-184-56h176c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H168c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm326.59-27.48c-1.98-1.63-22.19-17.91-46.59-37.53V96c0-17.67-14.33-32-32-32h-46.47c-4.13-3.31-7.71-6.16-10.2-8.14C337.23 38.19 299.44 0 256 0c-43.21 0-80.64 37.72-103.34 55.86-2.53 2.01-6.1 4.87-10.2 8.14H96c-17.67 0-32 14.33-32 32v30.98c-24.52 19.71-44.75 36.01-46.48 37.43A48.002 48.002 0 0 0 0 201.48V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.51c0-14.31-6.38-27.88-17.41-36.99zM256 32c21.77 0 44.64 16.72 63.14 32H192.9c18.53-15.27 41.42-32 63.1-32zM96 96h320v173.35c-32.33 26-65.3 52.44-86.59 69.34-16.85 13.43-50.19 45.68-73.41 45.31-23.21.38-56.56-31.88-73.41-45.32-21.29-16.9-54.24-43.33-86.59-69.34V96zM32 201.48c0-4.8 2.13-9.31 5.84-12.36 1.24-1.02 11.62-9.38 26.16-21.08v75.55c-11.53-9.28-22.51-18.13-32-25.78v-16.33zM480 464c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16V258.91c42.75 34.44 99.31 79.92 130.68 104.82 20.49 16.36 56.74 52.53 93.32 52.26 36.45.26 72.27-35.46 93.31-52.26C380.72 338.8 437.24 293.34 480 258.9V464zm0-246.19c-9.62 7.75-20.27 16.34-32 25.79v-75.54c14.44 11.62 24.8 19.97 26.2 21.12 3.69 3.05 5.8 7.54 5.8 12.33v16.3z"
                    ></path>
                  </svg>
                            </div>
                            <div className="content">
                                <div className="sub">Email</div>
                                <div className="main">info@rodio.com</div>
                            </div>
                        </div>
                    </a> */}
              <a >
                <div className="icon-box-alt">
              
                  <div className="content">
                    <div className="sub">Address</div>
                    <div className="main"> # B-63, 5th Cross,<br /> Dyavasandra Industrial Estate,
                      Mahadevapura Post,<br /> Bangalore-560048.</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="contact-form-wrapper">
          <div className="contact-form-block wow animate__animated custom-fadeInUp" data-wow-duration="2s">
  <h2 className="primary--heading">Write us a message!</h2>
  <form className="contact-form" id="contact-form" onSubmit={handleSubmit}>
    <div className="form-wrapper">
      {/* Full Name */}
      <div className="input-group">
        <input 
          type="text" 
          name="fullname" 
          placeholder="Full Name" 
          value={formData.fullname} 
          onChange={handleChange} 
          required style={{color:"black"}}
        />
      </div>

      {/* Email */}
      <div className="input-group">
        <input 
          type="email" 
          name="email" 
          placeholder="Email" 
          value={formData.email} 
          onChange={handleChange} 
          required style={{color:"black"}}
        />
      </div>

      {/* Phone Number */}
      <div className="input-group">
        <input 
          type="text" 
          name="phone" 
          placeholder="Phone Number" 
          value={formData.phone} 
          onChange={handleChange} 
          required style={{color:"black"}}
        />
      </div>

      {/* Subject */}
      <div className="input-group">
        <input 
          type="text" 
          name="subject" 
          placeholder="Subject" 
          value={formData.subject} 
          onChange={handleChange} 
          required style={{color:"black"}}
        />
      </div>

      {/* Message / Description */}
      <div className="input-group">
        <textarea 
          name="description" 
          placeholder="Describe your business challenges"
          value={formData.description} 
          onChange={handleChange} 
          required  style={{color:"black"}}
        ></textarea>
      </div>
    </div>

    {/* Submit Button */}
    <button type="submit" className="submit-btn">Send</button>
  </form>

  {/* Error & Success Messages */}
  <div id="error-message" className="alert error">
    One or more fields have an error. Please check and try again.
  </div>
  <div id="success-message" className="alert success">
    Thank you for your message. It has been sent.
  </div>
</div>

</div>

        </div>
      </div>
      <div className="back-to-top">
        <div className="back-to-top-inner">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path
              d="M363.9 330.7c-6.271 6.918-16.39 6.783-22.62 1.188L192 197.5l-149.3 134.4c-6.594 5.877-16.69 5.361-22.62-1.188C14.2 324.1 14.73 314 21.29 308.1l159.1-144c6.125-5.469 15.31-5.469 21.44 0l159.1 144C369.3 314 369.8 324.1 363.9 330.7z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  )
}

export default Contact
