import React, { useState, useRef } from 'react';

const ImageGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const dialogRef = useRef(null);
  
  const containerStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px'
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    margin: '20px 0'
  };

  const imageContainerStyle = {
    position: 'relative',
    width: '100%'
  };

  const buttonStyle = {
    width: '100%',
    padding: '0',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
  };

  const imageStyle = {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
    display: 'block'
  };

  const dialogStyle = {
    border: 'none',
    background: 'transparent',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    margintop:'30px',
    padding: '40px',
    maxWidth: '100vw',
    maxHeight: '100vh',
    
  };

  const dialogOverlayStyle = {
    position: 'relative',
    maxWidth: '1200px',
    margin: '0 auto',
    zIndex: 1000
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '-40px',
    right: '0',
    background: 'transparent',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '10px',
    fontSize: '24px'
  };

  const lightboxImageStyle = {
    width: '100%',
    maxHeight: '80vh',
    objectFit: 'contain',
    borderRadius: '8px',
    background: 'black'
  };

  const sliderButtonStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'rgba(255, 255, 255, 0.3)',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '20px',
    fontSize: '24px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s ease',
    zIndex: 1001,
    width: '50px',
    height: '50px'
  };

  // Add styles for dialog backdrop
  const dialogPolyfillStyle = document.createElement('style');
  dialogPolyfillStyle.textContent = `
    dialog::backdrop {
      background: rgba(0, 0, 0, 0.75);
    }
  `;
  document.head.appendChild(dialogPolyfillStyle);
  
  const images = [
    { 
      id: 1, 
      url: './assets/images_colored/a1.jpg',
      alt: 'Machine 3' 
    },
    { 
      id: 2, 
      url: './assets/images_colored/H2.jpg',
      alt: 'Machine 21' 
    },
    { 
      id: 3, 
      url: './assets/images_colored/e2.jpg',
      alt: 'Machine 1' 
    },
    { 
        id: 3, 
        url: './assets/images_colored/a2.jpg',
        alt: 'Machine 1' 
      },
  ];

  const handleImageClick = (image) => {
    const index = images.findIndex(img => img.id === image.id);
    setCurrentImageIndex(index);
    setSelectedImage(image);
    dialogRef.current?.showModal();
  };

  const handleClose = () => {
    dialogRef.current?.close();
    setSelectedImage(null);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    const newIndex = (currentImageIndex - 1 + images.length) % images.length;
    setCurrentImageIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    const newIndex = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  // Hover effect
  const handleMouseEnter = (e) => {
    e.currentTarget.style.transform = 'scale(1.05)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.transform = 'scale(1)';
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowLeft') {
      handlePrevImage(e);
    } else if (e.key === 'ArrowRight') {
      handleNextImage(e);
    } else if (e.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <div style={containerStyle}>
      <div style={gridStyle}>
        {images.map((image) => (
          <div key={image.id} style={imageContainerStyle}>
            <button
              onClick={() => handleImageClick(image)}
              style={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={image.url}
                alt={image.alt}
                style={imageStyle}
              />
            </button>
          </div>
        ))}
      </div>

      <dialog
        ref={dialogRef}
        style={dialogStyle}
        onClick={handleClose}
        onKeyDown={handleKeyDown}
      >
        <div 
          style={dialogOverlayStyle}
          onClick={e => e.stopPropagation()}
        >
          <button
            onClick={handleClose}
            style={closeButtonStyle}
          >
            ×
          </button>
          <button
            onClick={handlePrevImage}
            style={{...sliderButtonStyle, left: '20px'}}
          >
            ‹
          </button>
          <button
            onClick={handleNextImage}
            style={{...sliderButtonStyle, right: '20px'}}
          >
            ›
          </button>
          {selectedImage && (
            <img
              src={selectedImage.url}
              alt={selectedImage.alt}
              style={lightboxImageStyle}
            />
          )}
        </div>
      </dialog>
    </div>
  );
};

export default ImageGallery;