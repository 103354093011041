// import React from 'react';

// const ProcessFlow = () => {
//   const styles = {
//     container: {
//       padding: '20px',
//       maxWidth: '1200px',
//       margin: '0 auto',
//     },
//     header: {
//       backgroundColor: '#b8860b',
//       color: 'white',
//       padding: '10px 20px',
//       borderRadius: '4px',
//       marginBottom: '30px'
//     },
//     headerText: {
//       fontSize: '24px',
//       margin: 0
//     },
//     flowContainer: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(4, 1fr)',
//       gap: '20px',
//       marginBottom: '20px'
//     },
//     flowContainerBottom: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(3, 1fr)',
//       gap: '20px'
//     },
//     processStep: {
//       display: 'flex',
//       flexDirection: 'column',
//       alignItems: 'center',
//       gap: '10px'
//     },
//     arrow: {
//       backgroundColor: '#2e7d32',
//       color: 'white',
//       padding: '10px 20px',
//       borderRadius: '4px',
//       position: 'relative',
//       width: '80%',
//       textAlign: 'center',
//       fontWeight: 'bold'
//     },
//     arrowRight: {
//       '&::after': {
//         content: '""',
//         position: 'absolute',
//         right: '-10px',
//         top: '50%',
//         transform: 'translateY(-50%)',
//         borderTop: '10px solid transparent',
//         borderBottom: '10px solid transparent',
//         borderLeft: '10px solid #2e7d32'
//       }
//     },
//     imageContainer: {
//       border: '2px solid #1976d2',
//       borderRadius: '4px',
//       padding: '5px',
//       width: '100%',
//       height: '150px',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       backgroundColor: '#fff'
//     },
//     image: {
//       width: '100%',
//       height: '100%',
//       objectFit: 'cover'
//     }
//   };

//   const processSteps = [
//     { name: 'Incoming Inspection', image: '/api/placeholder/200/150' },
//     { name: 'RM - Storage', image: '/api/placeholder/200/150' },
//     { name: 'RM Preheating', image: '/api/placeholder/200/150' },
//     { name: 'Production', image: '/api/placeholder/200/150' },
//     { name: 'Packing & Despatch', image: '/api/placeholder/200/150' },
//     { name: 'Final Inspection', image: '/api/placeholder/200/150' },
//     { name: 'Secondary Operation', image: '/api/placeholder/200/150' }
//   ];

//   return (
//     <div style={styles.container}>
//       <div style={styles.header}>
//         <h2 style={styles.headerText}>3. PROCESS FLOW</h2>
//       </div>
      
//       <div>
//         <div style={styles.flowContainer}>
//           {processSteps.slice(0, 4).map((step, index) => (
//             <div key={index} style={styles.processStep}>
//               <div style={styles.arrow}>
//                 {step.name}
//               </div>
//               <div style={styles.imageContainer}>
//                 <img 
//                   src={step.image} 
//                   alt={step.name}
//                   style={styles.image}
//                 />
//               </div>
//             </div>
//           ))}
//         </div>
        
//         <div style={styles.flowContainerBottom}>
//           {processSteps.slice(4).reverse().map((step, index) => (
//             <div key={index} style={styles.processStep}>
//               <div style={styles.arrow}>
//                 {step.name}
//               </div>
//               <div style={styles.imageContainer}>
//                 <img 
//                   src={step.image} 
//                   alt={step.name}
//                   style={styles.image}
//                 />
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProcessFlow;

import React, { useState, useEffect } from 'react';

const ProcessFlow = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      padding: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    header: {
      backgroundColor: '#f8fafc',
      color: 'black',
      padding: '19px 25px',
      borderRadius: '4px',
      marginBottom: '30px',
      width: '100%',
    },
    headerText: {
      fontSize: '30px',
      margin: 0,
      fontWeight: 'bold',
      textAlign:'center'
    },
    flowContainer: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)',
      gap: '20px',
      marginBottom: '20px',
    },
    flowContainerBottom: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
      gap: '20px',
    },
    processStep: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '15px',
      width: '100%',
    },
    arrow: {
      backgroundColor: '#2e7d32',
      color: 'white',
      padding: '12px 25px',
      borderRadius: '4px',
      width: '100%',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '16px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      position: 'relative',
    },
    arrowContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
    },
    arrowIcon: {
      position: 'absolute',
      bottom: '-25px',
      color: '#2e7d32',
      fontSize: '20px',
      display: isMobile ? 'block' : 'none',
    },
    imageContainer: {
      border: '2px solid #1976d2',
      borderRadius: '4px',
      padding: '5px',
      width: '100%',
      height: isMobile ? '200px' : '150px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '2px',
    },
    mobileArrow: {
      textAlign: 'center',
      color: '#2e7d32',
      fontSize: '24px',
      margin: '10px 0',
      display: isMobile ? 'block' : 'none',
    }
  };

  const processSteps = [
    { name: 'Incoming Inspection', image: './assets/images_colored/p1.jpg' },
    { name: 'RM - Storage', image: './assets/images_colored/p2.jpg' },
    { name: 'RM Preheating', image: './assets/images_colored/p3.jpg' },
    { name: 'Production', image: './assets/images_colored/p4.jpg' },
    { name: 'Packing & Despatch', image: './assets/images_colored/p5.jpg' },
    { name: 'Final Inspection', image: './assets/images_colored/p6.jpg' },
    { name: 'Secondary Operation', image: './assets/images_colored/p7.jpg' }
  ];

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.headerText}>PROCESS FLOW</h2>
      </div>

      <div>
        <div style={styles.flowContainer}>
          {processSteps.slice(0, 4).map((step, index) => (
            <div key={index} style={styles.processStep}>
              <div style={styles.arrowContainer}>
                <div style={styles.arrow}>
                  {step.name}
                </div>
                {isMobile && index < 3 && <div style={styles.mobileArrow}>↓</div>}
              </div>
              <div style={styles.imageContainer}>
                <img
                  src={step.image}
                  alt={step.name}
                  style={styles.image}
                />
              </div>
            </div>
          ))}
        </div>

        <div style={styles.flowContainerBottom}>
          {processSteps.slice(4).reverse().map((step, index) => (
            <div key={index} style={styles.processStep}>
              <div style={styles.arrowContainer}>
                <div style={styles.arrow}>
                  {step.name}
                </div>
                {isMobile && index < 2 && <div style={styles.mobileArrow}>↓</div>}
              </div>
              <div style={styles.imageContainer}>
                <img
                  src={step.image}
                  alt={step.name}
                  style={styles.image}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProcessFlow;