import React from 'react'
import ProcessFlow from '../Service/ProcessFlow'
import FacilitiesPage from './FacilitiesPage'
import FacilitiesQAPage from './FacilitiesQAPage'
import MachineGallery from './MachineGallery'
import Core from './Core'

function Facilities() {
  return (
    <div>
      <section class="page-header-section page-header-colored" style={{marginTop:"10vh"}}>
        <div class="container">
          <div class="page-header-wrapper">
            <div class="page-header-content">
              <div class="page-title">
                <h1>Facilities</h1>
              </div>
              <div class="breadcrumbs">
                <ul>
                  <li>
                  <a  href='/'> Home </a>
                  </li>
  
                  <li>facility</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Core/>
      {/* <div class="work-v1-wrapper work-v1-light-section">
        <section class="work-v1-section work-v1-colored wow animate__animated custom-fadeInUp" data-wow-duration="2s">
            <div class="container wow animate__animated custom-fadeInUp" data-wow-duration="2s">
                <h2 class="primary--heading"style={{fontSize:'35px'}}>
                MACHINE BEHIND OUR PRODUCTION
                </h2>

                <div class="work-cards-wrapper row-masonry" >
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m1.jpg" alt="team-01"/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4>Moulding Shop</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m21.jpg" alt="team-01"/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4>Moulding Shop</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m3.jpg" alt="team-01"/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4>Moulding Shop</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m4.jpg" alt="team-01"/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4>Mold Storage</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m5.jpg" alt="team-01"/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4>Hot Runner Mould</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m6.jpg" alt="team-01"/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4>Moulding Shop</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m8.jpg" alt="team-01" style={{width:'113%'}}/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4> HAAS.(VMC MILLING)</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>
                    <div class="image-box">
                        <div class="team-member">
                            <div class="team-member-image">
                                <img src="./assets/images_colored/m7.jpg" alt="team-01" style={{width:'113%'}}/>
                            </div>
                            <div class="team-member-content">
                                <div class="team-member-content-inner">
                                    <h4> DMG-1035 V ecoline. (VMC)Moulding Shop</h4>
                                   
                                    
                                </div>
                            </div>
                            <div class="team-member-bg"></div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div> */}
    <MachineGallery/>
      <FacilitiesPage/>
      <FacilitiesQAPage/>
    


    
    
      
    </div>


  )
}

export default Facilities
