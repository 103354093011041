import React from 'react';

const Iso = () => {
  const styles = {
    container: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      // padding: '40px 20px',
      backgroundColor: 'white',
      marginTop:'30px'
    },
    header: {
      backgroundColor: '#FFC0CB',
      padding: '12px 24px',
      borderRadius: '8px',
      marginBottom: '40px',
   
      border: '2px solid #666',
      textAlign: 'center'
    },
    title: {
      fontSize: '28px',
      color: '#333',
      margin: 0,
      fontWeight: 'bold'
    },
    certContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
    //   backgroundColor: '#f8f9fa',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      margin: '0 auto',
      maxWidth: '800px'
    },
    certImage: {
      maxWidth: '100%',
      height: '60vh',
      borderRadius: '8px',
      transition: 'transform 0.3s ease',
      cursor: 'pointer'
    },
    '@media (max-width: 768px)': {
      container: {
        padding: '20px 16px'
      },
      header: {
        padding: '10px 16px',
        marginBottom: '24px'
      },
      title: {
        fontSize: '24px'
      },
      certContainer: {
        padding: '16px'
      }
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h2 style={styles.title}>ISO 9001 : 2015 CERTIFICATION</h2>
      </div>
      <div style={styles.certContainer}>
        <img 
          src="./assets/images_colored/iso.jpg"
          alt="ISO 9001:2015 Certificate"
          style={styles.certImage}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = 'scale(1.02)';
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
        />
      </div>
    </div>

  );
};

export default Iso;

// // import React from 'react';

// // const OrgChartPage = () => {
// //   return (
// //     <div style={{
// //       minHeight: '100vh',
// //       backgroundColor: '#f9fafb',
// //       padding: '20px'
// //     }}>
// //       {/* Desktop View */}
// //       <div style={{
// //         display: 'none',
// //         maxWidth: '1200px',
// //         margin: '0 auto',
// //         padding: '24px',
// //         '@media (min-width: 768px)': {
// //           display: 'block'
// //         }
// //       }}>
// //         <div style={{
// //           backgroundColor: 'white',
// //           borderRadius: '8px',
// //           boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
// //           overflow: 'hidden'
// //         }}>
// //           <div style={{
// //             borderBottom: '1px solid #e5e7eb',
// //             padding: '20px',
// //             textAlign: 'center'
// //           }}>
// //             <h1 style={{
// //               fontSize: '30px',
// //               fontWeight: 'bold',
// //               color: '#1f2937',
// //               margin: 0
// //             }}>
// //               Organizational Structure
// //             </h1>
// //           </div>
// //           <div style={{
// //             padding: '32px'
// //           }}>
// //             <img 
// //               src="./assets/images_colored/tree.jpg"
// //               alt="Organization Chart"
// //               style={{
// //                 width: '100%',
// //                 height: 'auto',
// //                 objectFit: 'contain'
// //               }}
// //             />
// //           </div>
// //         </div>
// //       </div>

// //       {/* Mobile View */}
// //       <div style={{
// //         display: 'block',
// //         '@media (min-width: 768px)': {
// //           display: 'none'
// //         }
// //       }}>
// //         <div style={{
// //           backgroundColor: 'white',
// //           padding: '16px',
// //           boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
// //           borderBottom: '1px solid #e5e7eb'
// //         }}>
// //           <h1 style={{
// //             fontSize: '20px',
// //             fontWeight: 'bold',
// //             color: '#1f2937',
// //             margin: 0,
// //             textAlign: 'center'
// //           }}>
// //             Organizational Structure
// //           </h1>
// //         </div>
// //         <div style={{
// //           padding: '16px'
// //         }}>
// //           <img 
// //             src="./assets/images_colored/tree.jpg"
// //             alt="Organization Chart"
// //             style={{
// //               width: '100%',
// //               height: 'auto',
// //               objectFit: 'contain'
// //             }}
// //           />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default OrgChartPage;



// import React from 'react';

// const FeatureGrid = () => {
//   const containerStyle = {
//     maxWidth: '1200px',
//     margin: '0 auto',
//     padding: '24px',
//     backgroundColor: 'white',
//     borderRadius: '8px',
//     boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
//   };

//   const headerStyle = {
//     textAlign: 'center',
//     marginBottom: '32px',
//   };

//   const descriptionStyle = {
//     color: '#4B5563',
//     marginBottom: '24px',
//     lineHeight: '1.6',
//   };

//   const titleStyle = {
//     fontSize: '1.25rem',
//     fontWeight: '600',
//     marginBottom: '32px',
//   };

//   const gridStyle = {
//     display: 'grid',
//     gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
//     gap: '32px',
//     padding: '16px',
//   };

//   const featureStyle = {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     textAlign: 'center',
//   };

//   const iconContainerStyle = {
//     backgroundColor: '#2563EB',
//     borderRadius: '50%',
//     padding: '16px',
//     marginBottom: '16px',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   };

//   const featureTitleStyle = {
//     fontSize: '0.875rem',
//     fontWeight: '500',
//     lineHeight: '1.4',
//   };

//   const features = [
//     {
//       icon: "🏠",
//       title: "Good house keeping",
//     },
//     {
//       icon: "👥",
//       title: "Customer requirements",
//     },
//     {
//       icon: "📋",
//       title: "Evaluations and trials",
//     },
//     {
//       icon: "🔍",
//       title: "Purchasing traceability and verification controls",
//     },
//     {
//       icon: "🤖",
//       title: "Requirements for implantable devices",
//     },
//     {
//       icon: "💬",
//       title: "Inward goods control and Proper communication",
//     },
//     {
//       icon: "📦",
//       title: "Labeling and packaging controls through designated norms",
//     },
//     {
//       icon: "📅",
//       title: "Shelf-life and preservation of the product",
//     }
//   ];

//   return (
//     <div style={containerStyle}>
//       <div style={headerStyle}>
//         <p style={descriptionStyle}>
//           Employees play a vital role in our success and we are committed to Investors in People As of Jan 2011 prem industries upgraded facility to 
//           9001:2008 quality certification with series of procedures and equipment in place to maximize quality control and ensure continuous improvement.
//         </p>
//         <h2 style={titleStyle}>
//           Special focus laid on the following has made Prem industries a best in class system oriented industry which includes the following:
//         </h2>
//       </div>
      
//       <div style={gridStyle}>
//         {features.map((feature, index) => (
//           <div key={index} style={featureStyle}>
//             <div style={iconContainerStyle}>
//               <span style={{ fontSize: '24px', color: 'white' }}>{feature.icon}</span>
//             </div>
//             <p style={featureTitleStyle}>{feature.title}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default FeatureGrid;