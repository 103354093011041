// import React, { useState, useRef } from 'react';

// const MachineGallery = () => {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const dialogRef = useRef(null);
  
//   const dialogStyle = {
//     border: 'none',
//     background: 'transparent',
//     width: '100%',
//     height: '100%',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     padding: '20px',
//     maxWidth: '100vw',
//     maxHeight: '100vh'
//   };

//   const dialogOverlayStyle = {
//     position: 'relative',
//     maxWidth: '1200px',
//     margin: '0 auto',
//     zIndex: 1000
//   };

//   const closeButtonStyle = {
//     position: 'absolute',
//     right: '0',
    
//     background: 'transparent',
//     border: 'none',
//     color: 'white',
//     cursor: 'pointer',
//     padding: '10px',
//     fontSize: '16px'
//   };

//   const lightboxImageStyle = {
//     width: '100%',
//     maxHeight: '80vh',
//     objectFit: 'contain',
//     borderRadius: '8px',
//     background: '',
    
  

//   };

//   // Add dialog backdrop style
//   const dialogPolyfillStyle = document.createElement('style');
//   dialogPolyfillStyle.textContent = `
//     dialog::backdrop {
//       background: rgba(0, 0, 0, 0.75);
//     }
//   `;
//   document.head.appendChild(dialogPolyfillStyle);

//   const handleImageClick = (image) => {
//     setSelectedImage(image);
//     dialogRef.current?.showModal();
//   };

//   const handleClose = () => {
//     dialogRef.current?.close();
//     setSelectedImage(null);
//   };

//   const machines = [
//     { id: 1, src: './assets/images_colored/m1.jpg', title: 'Moulding Shop' },
//     { id: 2, src: './assets/images_colored/m21.jpg', title: 'Moulding Shop' },
//     { id: 3, src: './assets/images_colored/m3.jpg', title: 'Moulding Shop' },
//     { id: 4, src: './assets/images_colored/m4.jpg', title: 'Mold Storage' },
//     { id: 5, src: './assets/images_colored/m5.jpg', title: 'Hot Runner Mould' },
//     { id: 6, src: './assets/images_colored/m6.jpg', title: 'Moulding Shop' },
//     { id: 7, src: './assets/images_colored/m8.jpg', title: 'HAAS.(VMC MILLING)' },
//     { id: 8, src: './assets/images_colored/m7.jpg', title: 'DMG-1035 V ecoline. (VMC)Moulding Shop' }
//   ];

//   return (
//     <div className="work-v1-wrapper work-v1-light-section">
//       <section className="work-v1-section work-v1-colored wow animate__animated custom-fadeInUp" data-wow-duration="2s">
//         <div className="container wow animate__animated custom-fadeInUp" data-wow-duration="2s">
//           <h2 className="primary--heading" style={{fontSize: '35px'}}>
//             MACHINE BEHIND OUR PRODUCTION
//           </h2>

//           <div className="work-cards-wrapper row-masonry">
//             {machines.map((machine) => (
//               <div key={machine.id} className="image-box">
//                 <div className="team-member" onClick={() => handleImageClick(machine)}>
//                   <div className="team-member-image">
//                     <img src={machine.src} alt={machine.title} style={machine.id === 7 || machine.id === 8 ? {width: '113%'} : {}} />
//                   </div>
//                   <div className="team-member-content">
//                     <div className="team-member-content-inner">
//                       <h4>{machine.title}</h4>
//                     </div>
//                   </div>
//                   <div className="team-member-bg"></div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <dialog
//         ref={dialogRef}
//         style={dialogStyle}
//         onClick={handleClose}
//       >
//         <div 
//           style={dialogOverlayStyle}
//           onClick={e => e.stopPropagation()}
//         >
//           <button
//             onClick={handleClose}
//             style={closeButtonStyle}
//           >
//             Close
//           </button>
//           {selectedImage && (
//             <img
//               src={selectedImage.src}
//               alt={selectedImage.title}
//               style={lightboxImageStyle}
//             />
//           )}
//         </div>
//       </dialog>
//     </div>
//   );
// };

// export default MachineGallery;


import React, { useState, useRef } from 'react';

const MachineGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const dialogRef = useRef(null);
  
  const dialogStyle = {
    border: 'none',
    background: 'transparent',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
    padding: '20px',
    maxWidth: '100vw',
    maxHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const dialogOverlayStyle = {
    position: 'relative',
    maxWidth: '90vw', // Increased from 1200px to 90% of viewport width
    width: '90vw',    // Added explicit width
    margin: '0 auto',
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: '-40px',
    top: '-40px',
    background: 'transparent',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontFamily: 'Arial, sans-serif',
    width: '30px',
    height: '30px',
    lineHeight: '24px',
    zIndex: 1001
  };

  const lightboxImageStyle = {
    width: '100%',
    height: '85vh',    // Increased height
    objectFit: 'contain',
    borderRadius: '8px'
  };

  // Add dialog backdrop style
  const dialogPolyfillStyle = document.createElement('style');
  dialogPolyfillStyle.textContent = `
    dialog::backdrop {
      background: rgba(0, 0, 0, 0.75);
    }
    dialog {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
    }
    dialog[open] {
      opacity: 1;
      visibility: visible;
    }
  `;
  document.head.appendChild(dialogPolyfillStyle);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    dialogRef.current?.showModal();
  };

  const handleClose = () => {
    dialogRef.current?.close();
    setSelectedImage(null);
  };

  const machines = [
    { id: 1, src: './assets/images_colored/m1.jpg', title: 'Moulding Shop' },
    { id: 2, src: './assets/images_colored/m21.jpg', title: 'Moulding Shop' },
    { id: 3, src: './assets/images_colored/m3.jpg', title: 'Moulding Shop' },
    { id: 4, src: './assets/images_colored/m4.jpg', title: 'Mold Storage' },
    { id: 5, src: './assets/images_colored/m5.jpg', title: 'Hot Runner Mould' },
    { id: 6, src: './assets/images_colored/m6.jpg', title: 'Moulding Shop' },
    { id: 5, src: './assets/images_colored/m8.jpg', title: 'HAAS.(VMC MILLING)' },
    { id: 6, src: './assets/images_colored/m7.jpg', title: 'DMG-1035 V ecoline. (VMC)Moulding Shop' },
    
    // { id: 7, src: './assets/images_colored/m8.jpg', title: 'HAAS.(VMC MILLING)' },
    // { id: 8, src: './assets/images_colored/m7.jpg', title: 'DMG-1035 V ecoline. (VMC)Moulding Shop' }
  ];

  return (
    <div className="work-v1-wrapper work-v1-light-section">
      <section className="work-v1-section work-v1-colored wow animate__animated custom-fadeInUp" data-wow-duration="2s">
        <div className="container wow animate__animated custom-fadeInUp" data-wow-duration="2s">
          <h2 className="primary--heading" style={{fontSize: '35px'}}>
            MACHINE BEHIND OUR PRODUCTION
          </h2>

          <div className="work-cards-wrapper row-masonry">
            {machines.map((machine) => (
              <div key={machine.id} className="image-box">
                <div className="team-member" onClick={() => handleImageClick(machine)}>
                  <div className="team-member-image">
                    <img src={machine.src} alt={machine.title} style={machine.id === 7 || machine.id === 8 ? {width: '113%'} : {}} />
                  </div>
                  <div className="team-member-content">
                    <div className="team-member-content-inner">
                      <h4>{machine.title}</h4>
                    </div>
                  </div>
                  <div className="team-member-bg"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <dialog
        ref={dialogRef}
        style={dialogStyle}
        onClick={handleClose}
      >
        {selectedImage && (
          <div 
            style={dialogOverlayStyle}
            onClick={e => e.stopPropagation()}
          >
            <button
              onClick={handleClose}
              style={closeButtonStyle}
            >
              ×
            </button>
            <img
              src={selectedImage.src}
              alt={selectedImage.title}
              style={lightboxImageStyle}
            />
          </div>
        )}
      </dialog>
    </div>
  );
};

export default MachineGallery;



// import React, { useState, useRef } from 'react';

// const MachineGallery = () => {
//   const [selectedImage, setSelectedImage] = useState(null);
//   const dialogRef = useRef(null);
  
//   const dialogStyle = {
//     border: 'none',
//     background: 'transparent',
//     width: '100%',
//     height: '100%',
//     position: 'fixed',
//     top: '0',
//     left: '0',
//     padding: '20px',
//     maxWidth: '100vw',
//     maxHeight: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center'
//   };

//   const dialogOverlayStyle = {
//     position: 'relative',
//     maxWidth: '1200px',
//     margin: '0 auto',
//     zIndex: 1000
//   };

//   const closeButtonStyle = {
//     position: 'absolute',
//     right: '-40px',
//     top: '-40px',
//     background: 'transparent',
//     border: 'none',
//     color: 'white',
//     cursor: 'pointer',
//     padding: '10px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     fontSize: '24px',
//     fontFamily: 'Arial, sans-serif',
//     width: '30px',
//     height: '30px',
//     lineHeight: '24px'
//   };

//   const lightboxImageStyle = {
//     width: '100%',
//     maxHeight: '80vh',
//     objectFit: 'contain',
//     borderRadius: '8px'
//   };

//   // Add dialog backdrop style
//   const dialogPolyfillStyle = document.createElement('style');
//   dialogPolyfillStyle.textContent = `
//     dialog::backdrop {
//       background: rgba(0, 0, 0, 0.75);
//     }
//   `;
//   document.head.appendChild(dialogPolyfillStyle);

//   const handleImageClick = (image) => {
//     setSelectedImage(image);
//     dialogRef.current?.showModal();
//   };

//   const handleClose = () => {
//     dialogRef.current?.close();
//     setSelectedImage(null);
//   };

//   const machines = [
//     { id: 1, src: './assets/images_colored/m1.jpg', title: 'Moulding Shop' },
//     { id: 2, src: './assets/images_colored/m21.jpg', title: 'Moulding Shop' },
//     { id: 3, src: './assets/images_colored/m3.jpg', title: 'Moulding Shop' },
//     { id: 4, src: './assets/images_colored/m4.jpg', title: 'Mold Storage' },
//     { id: 5, src: './assets/images_colored/m5.jpg', title: 'Hot Runner Mould' },
//     { id: 6, src: './assets/images_colored/m6.jpg', title: 'Moulding Shop' },
//     { id: 7, src: './assets/images_colored/m8.jpg', title: 'HAAS.(VMC MILLING)' },
//     { id: 8, src: './assets/images_colored/m7.jpg', title: 'DMG-1035 V ecoline. (VMC)Moulding Shop' }
//   ];

//   return (
//     <div className="work-v1-wrapper work-v1-light-section">
//       <section className="work-v1-section work-v1-colored wow animate__animated custom-fadeInUp" data-wow-duration="2s">
//         <div className="container wow animate__animated custom-fadeInUp" data-wow-duration="2s">
//           <h2 className="primary--heading" style={{fontSize: '35px'}}>
//             MACHINE BEHIND OUR PRODUCTION
//           </h2>

//           <div className="work-cards-wrapper row-masonry">
//             {machines.map((machine) => (
//               <div key={machine.id} className="image-box">
//                 <div className="team-member" onClick={() => handleImageClick(machine)}>
//                   <div className="team-member-image">
//                     <img src={machine.src} alt={machine.title} style={machine.id === 7 || machine.id === 8 ? {width: '113%'} : {}} />
//                   </div>
//                   <div className="team-member-content">
//                     <div className="team-member-content-inner">
//                       <h4>{machine.title}</h4>
//                     </div>
//                   </div>
//                   <div className="team-member-bg"></div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>

//       <dialog
//         ref={dialogRef}
//         style={dialogStyle}
//         onClick={handleClose}
//       >
//         <div 
//           style={dialogOverlayStyle}
//           onClick={e => e.stopPropagation()}
//         >
//           <button
//             onClick={handleClose}
//             style={closeButtonStyle}
//           >
//             ×
//           </button>
//           {selectedImage && (
//             <img
//               src={selectedImage.src}
//               alt={selectedImage.title}
//               style={lightboxImageStyle}
//             />
//           )}
//         </div>
//       </dialog>
//     </div>
//   );
// };

// export default MachineGallery;