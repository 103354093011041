import React from "react";

const HealthCare = () => {
    const products = [
        {
          id: 1,
        //   name: "Engine Mounts",
        //   description: "High-quality engine mounts ensure stability and reduce vibrations, enhancing vehicle performance.",
          image: "./assets/images_colored/H1.jpg",
        },
        {
          id: 2,
        //   name: "Brake Components",
        //   description: "Reliable brake components for optimal safety and precision in braking performance.",
          image: "./assets/images_colored/H2.jpg",
        }
      ];

  const styles = {
    container: {
      maxWidth: "1200px",
      margin: "auto",
      padding: "20px",
      fontFamily: "Arial, sans-serif",
    },
    heroSection: {
      textAlign: "center",
      marginBottom: "40px",
    },
    heroTitle: {
      fontSize: "36px",
      fontWeight: "bold",
      marginTop: "20px",
      color: "#1e3a8a",
    },
    heroText: {
      fontSize: "18px",
      color: "#4b5563",
      marginTop: "18px",
        textAlign:'left'
    },
    bannerImage: {
      width: "100%",
      maxWidth: "800px",
      borderRadius: "10px",
      boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      margin: "20px auto",
    },
    header: {
      display: "flex",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      color: "#333",
      marginBottom: "20px",
    },
    arrowIcon: {
      marginRight: "10px",
      fontSize: "24px",
    },
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
      gap: "20px",
    },
    card: {
      backgroundColor: "white",
      borderRadius: "12px",
      boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
      overflow: "hidden",
      transition: "transform 0.3s",
      cursor: "pointer",
    },
    image: {
      width: "100%",
      height: "200px",
      objectFit: "cover",
    },
    cardContent: {
      padding: "16px",
    },
    cardTitle: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#374151",
    },
    cardDescription: {
      fontSize: "16px",
      color: "#6b7280",
      marginTop: "8px",
    },
  };

  return (
    <>
    <section
  className="page-header-section page-header-colored"
  style={{
    marginTop: "10vh",
    backgroundImage: "url('/assets/images_colored/Hhome1.jpeg')", 
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "50vh",
  }}
>
  <div className="container">
    <div className="page-header-wrapper">
      <div className="page-header-content">
        <div className="page-title">
          <h1 style={{color:"rgb(30, 58, 138)"}}>Products</h1>
        </div>
        <div className="breadcrumbs">
          <ul>
            <li>
              <a href="/"> Home </a>
            </li>
            <li>Products</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
    <div style={styles.container}>
      {/* Hero Section */}
      <div style={styles.heroSection}>
        <h1 style={styles.heroTitle}>Health Care Components</h1>
        <p style={styles.heroText}>
          At Sri Engineering, we provide top-quality healthcare components that enhance patient care and medical efficiency. Our products are designed to meet the highest standards of safety and reliability.
        </p>
        
      </div>

      {/* Section Header
      <div style={styles.header}>
        <span style={styles.arrowIcon}>➤</span> Health Care
      </div> */}

      {/* Product Section */}
      <div style={styles.gridContainer}>
        {products.map((product) => (
          <div
            key={product.id}
            style={styles.card}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            <img src={product.image} alt={product.name} style={styles.image} />
            <div style={styles.cardContent}>
              <h2 style={styles.cardTitle}>{product.name}</h2>
              <p style={styles.cardDescription}>{product.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default HealthCare;
