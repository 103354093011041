// import React from 'react';

// const Milestones = () => {
//   const milestones = [
//     {
//       year: 2001,
//       description: <p>Sri Engineering Established by <span >Mr. C. Shekar,Mr. Ilangova</span>  
      
//       <span style={{display:'block'}}>Mr. Rajendran</span>  
//    </p>
//     },
//     {
//       year: 2001,
//       description: 'Established the Tool Room facility'
//     },
//     {
//       year: 2003,
//       description: 'First Moulding machine Installed & Supplying the components'
//     },
//     {
//       year: 2004,
//       description: 'Quality management system has been certified for ISO 9001: 2000'
//     },
//     {
//       year: 2009,
//       description: 'Added three new moulding machine'
//     },
//     {
//       year: 2010,
//       description: 'Added two moulding machine for production'
//     },
//     {
//       year: 2011,
//       description: 'Certified as SQ Mark Approved supplier by M/S Hyundai'
//     },
//     {
//       year: 2012,
//       description: 'Procured Rapid I to improve our inspection Quality'
//     },
//     {
//       year: 2013,
//       description: 'Added New Products'
//     },
//     {
//       year: 2014,
//       description: 'Moved to New (own) Premises, Added 3 more Vertical Moulding m/c'
//     },
//     {
//       year: 2015,
//       description: 'Added VMC MILLING (DMG -1035)'
//     },
//     {
//       year: 2017,
//       description: 'Added new customers: Colgate Palmolive'
//     },
//     {
//       year: 2018,
//       description: 'Developed new customers for export order PP to Parker & Upgraded to ISO 9001-2015 version'
//     },
//     {
//       year: 2019,
//       description: 'Revival of Orders from Kwangjin and New Customer Stanzen'
//     },
//     {
//       year: 2020,
//       description: 'New Development/ Customer KWANG JIN LTD'
//     },
//     {
//       year: 2021,
//       description: 'Water Cooling Chiller (Spec 200 Deg.C)'
//     },
//     {
//       year: 2021,
//       description: 'One More Injection Moulding Machine (200 TN)'
//     },
//     {
//       year: 2022,
//       description: 'Green Supplier for "MARUTI" 2021-22'
//     }
//   ];

//   const styles = {
//     container: {
//       width: '100%',
//       maxWidth: '1200px',
//       margin: '0 auto',
//       backgroundColor: 'white',
//       borderRadius: '8px',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       overflow: 'hidden'
//     },
//     header: {
//       backgroundColor: '#f59e0b',
//       color: 'white',
//       padding: '16px',
//       marginBottom: '20px'
//     },
//     title: {
//       fontSize: '24px',
//       fontWeight: 'bold',
//       margin: 0
//     },
//     timelineContainer: {
//       padding: '24px',
//       display: 'flex',
//       flexWrap: 'wrap',
//       gap: '20px',
//       justifyContent: 'center'
//     },
//     timelineItem: {
//       display: 'flex',
//       alignItems: 'flex-start',
//       gap: '16px',
//       width: '400px',
//       marginBottom: '16px'
//     },
//     yearBadge: {
//       width: '64px',
//       height: '64px',
//       backgroundColor: '#bfdbfe',
//       borderRadius: '50%',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       color: '#1e40af',
//       fontWeight: 'bold',
//       flexShrink: 0
//     },
//     descriptionBox: {
//       flex: 3,
//       padding: '16px',
//       backgroundColor: '#f3f4f6',
//       borderRadius: '8px',
//       minHeight: '60px',
//       display: 'flex',
//       alignItems: 'center',
//       boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
//       background: 'linear-gradient(to right, #f3f4f6, white)'
//     },
//     description: {
//       color: '#1f2937',
//       margin: 0
//     }
//   };

//   return (
//     <div style={styles.container}>
//       <div style={styles.header}>
//         <h1 style={styles.title}>MILE STONES</h1>
//       </div>
//       <div style={styles.timelineContainer}>
//         {milestones.map((milestone, index) => (
//           <div key={index} style={styles.timelineItem}>
//             <div style={styles.yearBadge}>
//               {milestone.year}
//             </div>
//             <div style={styles.descriptionBox}>
//               <p style={styles.description}>
//                 {milestone.description}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Milestones;


// import React from 'react';

// const Milestones = () => {
//   const milestones = [
//     {
//       year: 2001,
//       description: <p>Sri Engineering Established by <span>Mr. C. Shekar,Mr. Ilangova</span>  
      
//       <span style={{display:'block'}}>Mr. Rajendran</span>  
//    </p>
//     },
//     {
//       year: 2001,
//       description: 'Established the Tool Room facility'
//     },
//     {
//       year: 2003,
//       description: 'First Moulding machine Installed & Supplying the components'
//     },
//     {
//       year: 2004,
//       description: 'Quality management system has been certified for ISO 9001: 2000'
//     },
//     {
//       year: 2009,
//       description: 'Added three new moulding machine'
//     },
//     {
//       year: 2010,
//       description: 'Added two moulding machine for production'
//     },
//     {
//       year: 2011,
//       description: 'Certified as SQ Mark Approved supplier by M/S Hyundai'
//     },
//     {
//       year: 2012,
//       description: 'Procured Rapid I to improve our inspection Quality'
//     },
//     {
//       year: 2013,
//       description: 'Added New Products'
//     },
//     {
//       year: 2014,
//       description: 'Moved to New (own) Premises, Added 3 more Vertical Moulding m/c'
//     },
//     {
//       year: 2015,
//       description: 'Added VMC MILLING (DMG -1035)'
//     },
//     {
//       year: 2017,
//       description: 'Added new customers: Colgate Palmolive'
//     },
//     {
//       year: 2018,
//       description: 'Developed new customers for export order PP to Parker & Upgraded to ISO 9001-2015 version'
//     },
//     {
//       year: 2019,
//       description: 'Revival of Orders from Kwangjin and New Customer Stanzen'
//     },
//     {
//       year: 2020,
//       description: 'New Development/ Customer KWANG JIN LTD'
//     },
//     {
//       year: 2021,
//       description: 'Water Cooling Chiller (Spec 200 Deg.C)'
//     },
//     {
//       year: 2021,
//       description: 'One More Injection Moulding Machine (200 TN)'
//     },
//     {
//       year: 2022,
//       description: 'Green Supplier for "MARUTI" 2021-22'
//     }
//   ];

//   const styles = {
//     container: {
//       width: '100%',
//       maxWidth: '1200px',
//       margin: '0 auto',
//       backgroundColor: 'white',
//       borderRadius: '8px',
//       boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//       overflow: 'hidden'
//     },
//     header: {
//       backgroundColor: '#f59e0b',
//       color: 'white',
//       padding: '16px',
//       marginBottom: '20px',
//       animation: 'slideDown 0.5s ease-out'
//     },
//     title: {
//       fontSize: '24px',
//       fontWeight: 'bold',
//       margin: 0,
//       textAlign: 'center'
//     },
//     timelineContainer: {
//       padding: '24px',
//       display: 'flex',
//       flexWrap: 'wrap',
//       gap: '20px',
//       justifyContent: 'flex-start'
//     },
//     timelineItem: {
//       display: 'flex',
//       alignItems: 'flex-start',
//       gap: '16px',
//       width: 'calc(33.333% - 14px)', // Desktop default
//       marginBottom: '16px',
//       animation: 'fadeIn 0.5s ease-out',
//       transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//       cursor: 'pointer',
//       '@media (max-width: 1024px)': {
//         width: 'calc(50% - 10px)' // Tablet view
//       },
//       '@media (max-width: 768px)': {
//         width: '100%' // Mobile view
//       }
//     },
//     yearBadge: {
//       width: '64px',
//       height: '64px',
//       backgroundColor: '#bfdbfe',
//       borderRadius: '50%',
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center',
//       color: '#1e40af',
//       fontWeight: 'bold',
//       flexShrink: 0,
//       transition: 'transform 0.3s ease, background-color 0.3s ease',
//       '@media (max-width: 480px)': {
//         width: '50px',
//         height: '50px',
//         fontSize: '14px'
//       }
//     },
//     descriptionBox: {
//       flex: 3,
//       padding: '16px',
//       backgroundColor: '#f3f4f6',
//       borderRadius: '8px',
//       minHeight: '60px',
//       display: 'flex',
//       alignItems: 'center',
//       boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
//       background: 'linear-gradient(to right, #f3f4f6, white)',
//       transition: 'transform 0.3s ease',
//       '@media (max-width: 480px)': {
//         padding: '12px',
//         fontSize: '14px'
//       }
//     },
//     description: {
//       color: '#1f2937',
//       margin: 0
//     }
//   };

//   React.useEffect(() => {
//     const styleSheet = document.createElement("style");
//     styleSheet.textContent = `
//       @keyframes fadeIn {
//         from {
//           opacity: 0;
//           transform: translateY(20px);
//         }
//         to {
//           opacity: 1;
//           transform: translateY(0);
//         }
//       }

//       @keyframes slideDown {
//         from {
//           transform: translateY(-20px);
//           opacity: 0;
//         }
//         to {
//           transform: translateY(0);
//           opacity: 1;
//         }
//       }

//       .timeline-item {
//         animation: fadeIn 0.5s ease-out;
//         transition: transform 0.3s ease, box-shadow 0.3s ease;
//       }

//       .timeline-item:hover {
//         transform: translateY(-5px);
//         box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
//       }

//       .year-badge:hover {
//         transform: scale(1.1);
//         background-color: #93c5fd !important;
//       }

//       .description-box:hover {
//         transform: translateX(5px);
//       }

//       /* Responsive Styles */
//       @media (max-width: 1024px) {
//         .timeline-item {
//           width: calc(50% - 10px) !important;
//         }
//       }

//       @media (max-width: 768px) {
//         .timeline-item {
//           width: 100% !important;
//         }
//         .container {
//           padding: 12px;
//         }
//       }

//       @media (max-width: 480px) {
//         .year-badge {
//           width: 50px !important;
//           height: 50px !important;
//           font-size: 14px !important;
//         }
//         .description-box {
//           padding: 12px !important;
//           font-size: 14px !important;
//         }
//         .title {
//           font-size: 20px !important;
//         }
//       }
//     `;
//     document.head.appendChild(styleSheet);
//     return () => document.head.removeChild(styleSheet);
//   }, []);

//   // Create a style tag for the animations
//   React.useEffect(() => {
//     const styleSheet = document.createElement("style");
//     styleSheet.textContent = `
//       @keyframes fadeIn {
//         from {
//           opacity: 0;
//           transform: translateY(20px);
//         }
//         to {
//           opacity: 1;
//           transform: translateY(0);
//         }
//       }

//       @keyframes slideDown {
//         from {
//           transform: translateY(-20px);
//           opacity: 0;
//         }
//         to {
//           transform: translateY(0);
//           opacity: 1;
//         }
//       }

//       .timeline-item {
//         animation: fadeIn 0.5s ease-out;
//         transition: transform 0.3s ease, box-shadow 0.3s ease;
//       }

//       .timeline-item:hover {
//         transform: translateY(-5px);
//         box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
//       }

//       .year-badge {
//         transition: transform 0.3s ease, background-color 0.3s ease;
//       }

//       .year-badge:hover {
//         transform: scale(1.1);
//         background-color: #93c5fd !important;
//       }

//       .description-box {
//         transition: transform 0.3s ease;
//       }

//       .description-box:hover {
//         transform: translateX(5px);
//       }
//     `;
//     document.head.appendChild(styleSheet);
//     return () => document.head.removeChild(styleSheet);
//   }, []);

//   return (
//     <div style={styles.container}>
//       <div style={styles.header}>
//         <h1 style={styles.title}>MILE STONES</h1>
//       </div>
//       <div style={styles.timelineContainer}>
//         {milestones.map((milestone, index) => (
//           <div key={index} style={styles.timelineItem}>
//             <div style={styles.yearBadge}>
//               {milestone.year}
//             </div>
//             <div style={styles.descriptionBox}>
//               <p style={styles.description}>
//                 {milestone.description}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Milestones;



import React from 'react';

const Milestones = () => {
    const milestones = [
        {
          year: 2001,
          description: <p>Sri Engineering Established by <span>Mr. C. Shekar,Mr. Ilangova</span>  
          
          <span style={{display:'block'}}>Mr. Rajendran</span>  
       </p>
        },
        {
          year: 2001,
          description: 'Established the Tool Room facility'
        },
        {
          year: 2003,
          description: 'First Moulding machine Installed & Supplying the components'
        },
        {
          year: 2004,
          description: 'Quality management system has been certified for ISO 9001: 2000'
        },
        {
          year: 2009,
          description: 'Added three new moulding machine'
        },
        {
          year: 2010,
          description: 'Added two moulding machine for production'
        },
        {
          year: 2011,
          description: 'Certified as SQ Mark Approved supplier by M/S Hyundai'
        },
        {
          year: 2012,
          description: 'Procured Rapid I to improve our inspection Quality'
        },
        {
          year: 2013,
          description: 'Added New Products'
        },
        {
          year: 2014,
          description: 'Moved to New (own) Premises, Added 3 more Vertical Moulding m/c'
        },
        {
          year: 2015,
          description: 'Added VMC MILLING (DMG -1035)'
        },
        {
          year: 2017,
          description: 'Added new customers: Colgate Palmolive'
        },
        {
          year: 2018,
          description: 'Developed new customers for export order PP to Parker & Upgraded to ISO 9001-2015 version'
        },
        {
          year: 2019,
          description: 'Revival of Orders from Kwangjin and New Customer Stanzen'
        },
        {
          year: 2020,
          description: 'New Development/ Customer KWANG JIN LTD'
        },
        {
          year: 2021,
          description: 'Water Cooling Chiller (Spec 200 Deg.C)'
        },
        {
          year: 2021,
          description: 'One More Injection Moulding Machine (200 TN)'
        },
        {
          year: 2022,
          description: 'Green Supplier for "MARUTI" 2021-22'
        }
      ];

  const styles = {
    container: {
      width: '100%',
      maxWidth: '1200px',
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      marginTop:'20px'
    },
    header: {
      backgroundColor: '#f1f5f9',
      color: 'black',
      padding: '16px',
      marginBottom: '20px',
      animation: 'slideDown 0.5s ease-out'
    },
    title: {
      fontSize: '38px',
      fontWeight: 'bold',
      margin: 0,
      textAlign: 'center'
    },
    timelineContainer: {
      padding: '24px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      justifyContent: 'flex-start'
    },
    timelineItem: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '16px',
      width: 'calc(33.333% - 14px)', // Desktop default
      marginBottom: '16px',
      animation: 'fadeIn 0.5s ease-out',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      cursor: 'pointer',
      '@media (max-width: 1024px)': {
        width: 'calc(50% - 10px)' // Tablet view
      },
      '@media (max-width: 768px)': {
        width: '100%' // Mobile view
      }
    },
    yearBadge: {
      width: '64px',
      height: '64px',
      backgroundColor: '#bfdbfe',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#1e40af',
      fontWeight: 'bold',
      flexShrink: 0,
      transition: 'transform 0.3s ease, background-color 0.3s ease',
      '@media (max-width: 480px)': {
        width: '50px',
        height: '50px',
        fontSize: '14px'
      }
    },
    descriptionBox: {
      flex: 3,
      padding: '16px',
      backgroundColor: '#ff7800',
      borderRadius: '8px',
      minHeight: '60px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      background: 'linear-gradient(to right, #f3f4f6, white)',
      transition: 'transform 0.3s ease',
      '@media (max-width: 480px)': {
        padding: '12px',
        fontSize: '14px'
      }
    },
    description: {
      color: '#1f2937',
      margin: 0
    }
  };

  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = `
      @keyframes fadeIn {
        from {
          opacity: 0;
          transform: translateY(20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }

      @keyframes slideDown {
        from {
          transform: translateY(-20px);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .timeline-item {
        animation: fadeIn 0.5s ease-out;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
      }

      .timeline-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
      }

      .year-badge:hover {
        transform: scale(1.1);
        background-color: #93c5fd !important;
      }

      .description-box:hover {
        transform: translateX(5px);
      }

      /* Responsive Styles */
      @media (max-width: 1024px) {
        .timeline-item {
          width: calc(50% - 10px) !important;
        }
      }

      @media (max-width: 768px) {
        .timeline-item {
          width: 100% !important;
        }
        .container {
          padding: 12px;
        }
      }

      @media (max-width: 480px) {
        .year-badge {
          width: 50px !important;
          height: 50px !important;
          font-size: 14px !important;
        }
        .description-box {
          padding: 12px !important;
          font-size: 14px !important;
        }
        .title {
          font-size: 20px !important;
        }
      }
    `;
    document.head.appendChild(styleSheet);
    return () => document.head.removeChild(styleSheet);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>MILE STONES</h1>
      </div>
      <div style={styles.timelineContainer}>
        {milestones.map((milestone, index) => (
          <div key={index} style={styles.timelineItem} className="timeline-item">
            <div style={styles.yearBadge} className="year-badge">
              {milestone.year}
            </div>
            <div style={styles.descriptionBox} className="description-box">
              <p style={styles.description}>
                {milestone.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Milestones;