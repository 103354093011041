import React, { useState, useEffect } from 'react';

const Products = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: isMobile ? '10px' : '20px',
      backgroundColor: '#fff',
      boxShadow: '0 0 20px rgba(0,0,0,0.1)',
      borderRadius: '10px',
      overflow: 'hidden',
      marginTop:'20px',
    },
    sectionTitle: {
        backgroundColor: '#f8fafc',
        color: 'black',
        padding: isMobile ? '8px 15px' : '10px 20px',
        borderRadius: '5px',
        fontSize: isMobile ? '27px' : '27px',
        fontWeight: 'bold',
        marginBottom: isMobile ? '20px' : '30px',
        display: 'flex',  // Use flexbox for centering
        alignItems: 'center',  
        justifyContent: 'center',  // Centers text horizontally
        textAlign: 'center',  // Ensures text is centered
        width: '100%', // Ensures it spans the container for proper centering
      }
      ,
    productSection: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: isMobile ? '25px' : '40px',
      padding: isMobile ? '15px' : '20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      gap: isMobile ? '20px' : '0'
    },
    productsGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: isMobile ? '20px' : '30px'
    },
    productImage: {
      width: isMobile ? '100%' : '300px',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '8px',
      border: '1px solid #ddd'
    },
    productImagesContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      width: isMobile ? '100%' : 'auto'
    },
    smallProductImage: {
      width: isMobile ? '100%' : '300px',
      height: '100px',
      objectFit: 'cover',
      borderRadius: '8px',
      border: '1px solid #ddd'
    },
    arrow: {
      fontSize: isMobile ? '110px' : '120px',
      color: '#ff7800',
      margin: isMobile ? '10px 0' : '0 40px',
      transform: isMobile ? 'rotate(90deg)' : 'none'
    },
    customerLogos: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: isMobile ? '10px' : '20px',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: isMobile ? '100%' : '400px',
      padding: isMobile ? '10px' : '0'
    },
    customerLogo: {
      width: isMobile ? '80px' : '100px',
      height: isMobile ? '48px' : '60px',
      objectFit: 'contain',
      padding: isMobile ? '5px' : '10px',
      backgroundColor: 'white',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.sectionTitle}>MAJOR PRODUCTS & CUSTOMERS</h2>
      
      <div style={styles.productsGrid}>
        {/* Auto Parts Section */}
        <div style={styles.productSection}>
          <img 
            src="./assets/images_colored/pc1.jpg" 
            alt="Auto Parts" 
            style={styles.productImage}
          />
          <div style={styles.arrow}>→</div>
          <div style={styles.customerLogos}>
            <img src="./assets/images_colored/c21.png" alt="Hyundai" style={styles.customerLogo} />
            <img src="./assets/images_colored/c3.png" alt="IFB" style={styles.customerLogo} />
            <img src="./assets/images_colored/c8.png" alt="Nissan" style={styles.customerLogo} />
            <img src="./assets/images_colored/c4.jpeg" alt="Parker" style={styles.customerLogo} />
            <img src="./assets/images_colored/c5.jpeg" alt="VW" style={styles.customerLogo} />
          </div>
        </div>

        {/* Consumer Products Section */}
        <div style={styles.productSection}>
          <div style={styles.productImagesContainer}>
            <img src="./assets/images_colored/pc2.jpg" alt="Product 1" style={styles.smallProductImage} />
            <img src="./assets/images_colored/pc3.jpg" alt="Product 2" style={styles.smallProductImage} />
            <img src="./assets/images_colored/pc4.jpg" alt="Product 3" style={styles.smallProductImage} />
          </div>
          <div style={styles.arrow}>→</div>
          <div style={styles.customerLogos}>
            <img src="./assets/images_colored/c5.jpeg" alt="Gillette" style={styles.customerLogo} />
            <img src="./assets/images_colored/c9.jpeg" alt="P&G" style={styles.customerLogo} />
            <img src="./assets/images_colored/c6.jpeg" alt="HAL" style={styles.customerLogo} />
            <img src="./assets/images_colored/c10.png" alt="Bosch" style={styles.customerLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;