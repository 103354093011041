// import React, { useState } from 'react';
// import './WhatsAppButton.css'; // Import CSS for animations
// import { FaWhatsapp } from 'react-icons/fa'; 
// import { GiCancel } from "react-icons/gi";

// const WhatsAppButton = () => {
//   const phoneNumber = '9845202377';  

//   const [isOpen, setIsOpen] = useState(false);

//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   };
//   const handleClick = () => {
//     // WhatsApp API link
//     const url = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

//     // Open WhatsApp in a new tab or window
//     window.open(url, '_blank');
//   };
//   return (
//     <div className="whatsapp-container">
//       <button className="whatsapp-button" onClick={handleClick}>
//         <div className="glow-effect"></div>
//         <img
//           src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
//           alt="WhatsApp"
//         />
//       </button>
//     </div>
//   );
// };

  
// export default WhatsAppButton;


import React, { useState } from 'react';
import './WhatsAppButton.css'; // Import CSS for animations
import { FaWhatsapp } from 'react-icons/fa'; 
import { GiCancel } from "react-icons/gi";
import { ImWhatsapp } from "react-icons/im";
const WhatsAppPopup = () => {
  const phoneNumber = '+919845202377';  
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // WhatsApp URL for mobile or desktop
    const url = isMobile 
      ? `whatsapp://send?phone=${phoneNumber}` 
      : `https://web.whatsapp.com/send?phone=${phoneNumber}`;

    // Open WhatsApp in a new tab or window
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-container">
      <button className="whatsapp-button" onClick={handleClick}>
        <div className="glow-effect"></div>
        {/* <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp"
        /> */}
        <ImWhatsapp size={30} color='white'/>
      </button>
    </div>
  );
};

export default WhatsAppPopup;
